import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth'
import { wrapRequest } from '../nav'

export const doFetchListByArtist = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/page?artist_id=eq.${artistId}`, requestOptions)
})

export const pageMetricsWithFan = wrapRequest((pageId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify({ pageId }),
  }
  return fetch(`${openstageApi2Url}/pageMetricsWithFan`, requestOptions)
})

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/page?id=eq.${id}`, requestOptions)
})

export const doPostOne = wrapRequest((page) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(page),
  }
  return fetch(`${openstageApiCrudUrl}/page`, requestOptions)
})

export const doPublish = wrapRequest((pageId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(pageId),
  }
  return fetch(`${openstageApi2Url}/publishPage`, requestOptions)
})

export const doDeleteOne = wrapRequest((pageId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/page?id=eq.${pageId}`, requestOptions)
})

export const doPatchOne = wrapRequest((page) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(page),
  }
  return fetch(`${openstageApiCrudUrl}/page?id=eq.${page.id}`, requestOptions)
})

export const doGeneratePage = wrapRequest((page) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(page),
  }
  return fetch(`${openstageApiCrudUrl}/page`, requestOptions)
})
