import { createStore } from 'vuex'

import { authStoreModule } from './auth.module'
import { layout } from './layout'
import { organizationOne } from './agent/organization.one'
import { organizationList } from './agent/organization.list'
import { teamMembershipList } from './suitcase/team_membership.list'
import { teamMembershipOne } from './suitcase/team_membership.one'
import { artistList } from './agent/artist.list'
import { artistOne } from './agent/artist.one'
import { audienceList } from './agent/audience.list'
import { audienceOne } from './agent/audience.one'
import { broadcastList } from './agent/broadcast.list'
import { broadcastOne } from './agent/broadcast.one'
import { geographicalOne } from './agent/geographical.one'
import { fanOne } from './agent/fan.one'
import { fanArtistOne } from './agent/fan_artist.one'
import { fanList } from './agent/fan.list'
import { emailMasterTemplateList } from './agent/email_master_template.list'
import { emailMasterTemplateOne } from './agent/email_master_template.one'
import { pageList } from './agent/page.list'
import { emailTemplateOne } from './agent/email_template.one'
import { registration } from './registration.module'
import { discordGuildOne } from './agent/discord_guild.one'
import { gettingStarted } from './getting_started.module'
import { landingPageOne } from './agent/landingpage/landingpage.one.js'
import { catalogOne } from './agent/catalog/catalog.one.js'
import { catalogList } from './agent/catalog/catalog.list.js'
import { qrList } from './agent/qr/qr.list.js'
import { qrOne } from './agent/qr/qr.one.js'
import { activityFilter } from './agent/activity/activity.filter.js'
import { storiesFilter } from './agent/stories/stories.filter.js'
import { accountManagers } from './agent/accountmanagers/account.managers.js'
import { transactionList } from './agent/transaction.list.js'
import { phoneVerification } from './agent/phone.verification.js'

export default createStore({
  strict: import.meta.env.NODE_ENV !== 'production',
  modules: {
    auth: authStoreModule,
    registration,
    layout,
    organizationOne,
    organizationList,
    teamMembershipList,
    artistList,
    artistOne,
    audienceList,
    audienceOne,
    fanOne,
    fanArtistOne,
    broadcastList,
    broadcastOne,
    geographicalOne,
    fanList,
    teamMembershipOne,
    emailMasterTemplateList,
    emailMasterTemplateOne,
    pageList,
    emailTemplateOne,
    discordGuildOne,
    gettingStarted,
    landingPageOne,
    catalogOne,
    catalogList,
    qrList,
    qrOne,
    activityFilter,
    storiesFilter,
    accountManagers,
    transactionList,
    phoneVerification,
  },
})
