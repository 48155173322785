import {
  doQueryFanbase,
  doQueryFanbaseSummary,
  doFetchArtistFanTags,
  doQueryDownload,
  // fanQueryLimit,
} from '@/services/agent/fan.service'
import { sanitizeTags } from '@/services/util.service'
import { getDefaultCriteriaItem, normalizeCriteria } from '@/_helpers/queryCriteria'
import { getField, updateField } from 'vuex-map-fields'
import createHumps from 'lodash-humps/lib/createHumps'
import { snakeCase } from 'lodash'
const snakes = createHumps(snakeCase)

const RefreshTimeoutLength = 500
let globalAbortController = null

export const fanList = {
  namespaced: true,
  state: () => ({
    inProgress: false,
    list: [], // rename to fanbase?
    currentOffset: 0, // used for faked pagination

    // values for entire fanbase
    fanbaseCount: 0, // list is capped, so we need to track total count
    fanbaseEngagement: 0,
    fanbaseValue: 0,

    // Values for current fanbase criteria
    activeCriteria: [],
    fanbaseForecastCount: 0,
    fanbaseForecastEngagement: 0, //?
    fanbaseForecastValue: 0, //?

    artistFanTags: [], // move to artist?

    randomNumber: null,
    randomSeed: null,
    randomSelectionInProgress: false,
    downloadInProgress: false,

    timeout: null, // for refreshForecast setTimeout
  }),
  getters: {
    getField,
    isCriteriaItemInActiveCriteria: (state) => (criteriaType) => {
      if (!criteriaType) return false
      return !!state.activeCriteria?.find((f) => f.criteriaType === criteriaType)
    },
    activeCriteriaWithValues: (state) => {
      return normalizeCriteria(state.activeCriteria || [])
    },
    isFiltered: (state, getters) => {
      return !!getters.activeCriteriaWithValues.length
    },
    currentPaginationIndex: (state) => {
      if (!state.list.length) return 0
      return (state.list.length + state.currentOffset) % state.list.length
    },
    isRandomList: (state) => {
      return !!state.randomNumber
    },
  },
  mutations: {
    updateField,
    setInProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo
    },
    setList(state, list = []) {
      state.list = list
    },
    setCurrentOffset(state, newOffset = 0) {
      state.currentOffset = newOffset || 0
    },
    setActiveCriteria(state, activeCriteria = []) {
      state.activeCriteria = JSON.parse(JSON.stringify(activeCriteria))
    },
    setActiveCriteriaItem: (state, criteriaItem) => {
      const { criteriaType, ...rest } = criteriaItem
      if (!criteriaType) return
      const existing = state.activeCriteria.find((f) => f.criteriaType === criteriaType)
      const item = {
        criteriaType,
        ...(getDefaultCriteriaItem(criteriaType) || { criteriaType }),
        ...(existing || {}),
        ...(rest || {}),
      }
      if (existing) {
        Object.assign(existing, item)
      } else {
        state.activeCriteria.push(item)
      }
    },
    updateActiveCriteriaItemField: (state, criteriaType, fieldKey, fieldValue) => {
      if (!criteriaType || !fieldKey) return
      const existingItem = state.activeCriteria.find((f) => f.criteriaType === criteriaType)
      existingItem[fieldKey] = fieldValue
    },
    removeActiveCriteriaItem: (state, criteriaType = null) => {
      if (!criteriaType) return
      const index = state.activeCriteria?.findIndex((f) => f.criteriaType === criteriaType)
      if (index < 0) return
      state.activeCriteria.splice(index, 1)
    },
    updateObjectField(state, { objectByReference, field, value }) {
      objectByReference[field] = value
    },

    // probably not needed..
    setFanbaseCount(state, newCount = 0) {
      state.fanbaseCount = newCount
    },
    setFanbaseEngagement(state, newScore = 0) {
      state.fanbaseEngagement = newScore
    },
    setFanbaseValue(state, newValue = 0) {
      state.fanbaseValue = newValue
    },

    setFanbaseForecast(state, forecast = {}) {
      const { count = 0, engagement = 0, value = 0 } = forecast
      state.fanbaseForecastCount = count
      state.fanbaseForecastEngagement = engagement
      state.fanbaseForecastValue = value
    },
    setFanbaseForecastCount(state, newCount = 0) {
      state.fanbaseForecastCount = newCount
    },
    setFanbaseForecastEngagement(state, newScore = 0) {
      state.fanbaseForecastEngagement = newScore
    },
    setFanbaseForecastValue(state, newValue = 0) {
      state.fanbaseForecast = newValue
    },

    // TODO - this is artist context, move to artist store?
    setArtistFanTags(state, list = []) {
      state.artistFanTags = sanitizeTags(list)
    },

    setDownloadInProgress(state, yesOrNo = false) {
      state.downloadInProgress = yesOrNo
    },
    setRandomSelectionInProgress(state, yesOrNo = false) {
      state.randomSelectionInProgress = yesOrNo
    },
    setRandomSeed(state, seed = null) {
      state.randomSeed = seed
    },
    setRandomNumber(state, number = null) {
      state.randomNumber = number
    },

    setTimeout(state, callback) {
      state.timeout = setTimeout(() => {
        if (callback) callback()
      }, RefreshTimeoutLength)
    },
    clearTimeout(state) {
      if (state.timeout) {
        clearInterval(state.timeout)
      }
    },
  },
  actions: {
    initializeActiveCriteria: ({ commit, dispatch }, criteriaArray = []) => {
      commit('setInProgress', true)
      const activeCriteria = normalizeCriteria(
        (criteriaArray || []).filter((f) => !!f.criteriaType),
      ) // normalize?
      commit('setActiveCriteria', activeCriteria)
      dispatch('refreshForecast') // broadcastId?
      commit('setInProgress')
    },
    setActiveCriteriaForced: ({ commit, dispatch }, criteriaArray = []) => {
      commit('setInProgress', true)
      commit('setActiveCriteria', criteriaArray)
      dispatch('refreshForecast') // broadcastId?
      commit('setInProgress')
    },
    setActiveCriteriaItem: ({ state, getters, commit, dispatch }, criteriaItem) => {
      // console.log(criteriaItem)
      if (!criteriaItem?.criteriaType) return
      const activeCriteriaWithValuesBefore = JSON.stringify(getters.activeCriteriaWithValues)
      if (!state.activeCriteria) commit('setActiveCriteria')
      commit('setActiveCriteriaItem', criteriaItem)
      const activeCriteriaWithValuesAfter = JSON.stringify(getters.activeCriteriaWithValues)
      if (activeCriteriaWithValuesBefore !== activeCriteriaWithValuesAfter)
        dispatch('refreshForecast') // broadcastId?
    },
    updateObjectField: ({ getters, commit, dispatch }, { objectByReference, field, value }) => {
      if (!objectByReference || !field) return
      const activeCriteriaWithValuesBefore = JSON.stringify(getters.activeCriteriaWithValues)
      commit('updateObjectField', { objectByReference, field, value })
      const activeCriteriaWithValuesAfter = JSON.stringify(getters.activeCriteriaWithValues)
      if (activeCriteriaWithValuesBefore !== activeCriteriaWithValuesAfter)
        dispatch('refreshForecast') // broadcastId?
    },
    removeActiveCriteriaItem: ({ state, getters, commit, dispatch }, criteriaType) => {
      if (!criteriaType) return
      const activeCriteriaWithValuesBefore = JSON.stringify(getters.activeCriteriaWithValues)
      if (!state.activeCriteria) commit('setActiveCriteria')
      else commit('removeActiveCriteriaItem', criteriaType)
      const activeCriteriaWithValuesAfter = JSON.stringify(getters.activeCriteriaWithValues)
      if (activeCriteriaWithValuesBefore !== activeCriteriaWithValuesAfter)
        dispatch('refreshForecast') // broadcastId?
    },
    resetCriteria: ({ commit }) => {
      commit('setActiveCriteria')
      commit('setFanbaseForecast')
    },

    refreshForecast: ({ state, commit, dispatch }, payload = {}) => {
      // console.log('refreshForecast called')
      commit('setInProgress', true)
      if (state.timeout) commit('clearTimeout') // clear currently waiting timeout
      commit('setTimeout', () => dispatch('queryFanbaseForecast', payload))
    },

    // this applies the criteria filter to list
    queryFanbase: async ({ state, getters, commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('setInProgress', true)
      let fans = []
      try {
        const response = await doQueryFanbase(
          rootState.artistOne.one.id,
          snakes(getters.activeCriteriaWithValues),
          {},
          {},
          state.randomNumber || null,
          state.randomSeed || null,
        )
        if (response.status && response.status == 'error') {
          throw new Error('Could not fetch fans list')
        }
        fans = (response.items || []).map((fan) => ({
          ...fan,
          tags: fan?.tags?.length ? sanitizeTags(fan.tags) : [],
        }))
      } catch (error) {
        console.log(error)
      }
      commit('setList', fans)
      commit('setInProgress')
    },
    queryFanbaseForecast: async ({ getters, commit, rootState }, { broadcastId = null } = {}) => {
      // Check if a request is already in progress and abort it
      if (globalAbortController) {
        globalAbortController.abort()
        globalAbortController = null
      }

      // Ensure artist ID exists
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }

      commit('setInProgress', true)

      // Create a new AbortController for the new request
      globalAbortController = new AbortController()

      let forecast = {}

      try {
        const response = await doQueryFanbaseSummary(
          rootState.artistOne.one.id,
          snakes(getters.activeCriteriaWithValues),
          {},
          {},
          broadcastId,
          globalAbortController.signal  // Pass the abort signal to the request
        );

        if (!response) {
          throw new Error('Request was aborted')
        }

        if (response?.status === 'error' || response?.status === 'ERROR') {
          throw new Error('Could not fetch criteria forecast matching count')
        }

        if (response.count) forecast.count = response.count
        if (response.engagement) forecast.engagement = response.engagement
        if (response.value) forecast.value = response.value

        // Do not use finally block because we don't want to do this when a request is cancelled
        commit('setFanbaseForecast', forecast)
        commit('setInProgress')
        globalAbortController = null // Reset the global variable after the request is complete
      } catch (error) {
        if (error.name === 'AbortError' || error?.message?.includes('aborted')) {
          console.log('Request was aborted')
        } else {
          // Do not use finally block because we don't want to do this when a request is cancelled
          commit('setFanbaseForecast', forecast)
          commit('setInProgress')
          globalAbortController = null // Reset the global variable after the request is complete
        }
      }
    },

    fetchArtistFanTags: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('setInProgress', true)
      let tags = []
      try {
        const response = await doFetchArtistFanTags(rootState.artistOne.one.id)
        if (response?.status === 'error' || response?.status === 'ERROR') {
          throw new Error('Could not fetch list of existing tags in use')
        }
        tags = response
      } catch (error) {
        console.log(error)
      }
      commit('setArtistFanTags', tags || [])
      commit('setInProgress')
    },

    stepCurrentOffset: ({ state, commit }, add = 0) => {
      const total = state.list?.length || 0
      const nextProposed = state.currentOffset + add
      if (nextProposed <= 0)
        commit('setCurrentOffset', total) // last
      else if (nextProposed >= total)
        commit('setCurrentOffset', 0) // first
      else commit('setCurrentOffset', nextProposed) // neighbour
    },

    downloadFans: async ({ state, getters, commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('setDownloadInProgress', true)
      try {
        const csvContent = await doQueryDownload(
          rootState.artistOne.one.id,
          snakes(getters.activeCriteriaWithValues),
          state.randomNumber || null,
          (state.randomNumber && state.randomSeed) || null,
        )
        const blob = new Blob(['\ufeff', csvContent])
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', 'fans.csv')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.log(error)
      }
      commit('setDownloadInProgress')
    },
    setRandom: async ({ commit, dispatch, rootState }, number = null) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      if (!number) return
      commit('setRandomSelectionInProgress', true)
      commit('setRandomNumber', number)
      commit('setRandomSeed', Math.random() * 2.0 - 1.0)
      await dispatch('queryFanbase')
      commit('setRandomSelectionInProgress')
    },
    clearRandom: ({ commit, dispatch }) => {
      commit('setRandomSelectionInProgress')
      commit('setRandomNumber')
      commit('setRandomSeed')
      dispatch('queryFanbase')
    },
  },
}
