<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 452 188">
    <path
      d="M47.2 84.9c-9.7-3.6-15.1-6.4-15.1-10.8 0-3.7 3.1-5.9 8.5-5.9 10 0 20.3 3.9 27.3 7.3l4-24.7c-5.6-2.7-17.1-7.1-32.9-7.1-11.2 0-20.5 2.9-27.2 8.4-6.9 5.7-10.5 14-10.5 24 0 18.1 11.1 25.9 29.1 32.4 11.6 4.1 15.5 7.1 15.5 11.6 0 4.4-3.7 6.9-10.5 6.9-8.4 0-22.3-4.1-31.3-9.5l-4 24.9c7.7 4.4 22.1 8.9 37.1 8.9 11.9 0 21.7-2.8 28.4-8.1 7.5-5.9 11.3-14.5 11.3-25.7-.1-18.3-11.4-26-29.7-32.6zm94.7-14.5 4-24.5h-21.1V16.1l-28.4 4.7-4.1 25.1-10 1.6-3.7 22.9h13.7v48.1c0 12.5 3.2 21.2 9.7 26.5 5.5 4.4 13.3 6.5 24.4 6.5 8.5 0 13.7-1.5 17.3-2.4v-26c-2 .5-6.5 1.5-9.6 1.5-6.5 0-9.3-3.3-9.3-10.9V70.4h17.1zm63.4-26c-9.3 0-16.8 4.9-19.7 13.7l-2-12.3h-28.9v103.7h33.1V82.3c4.1-5.1 10-6.9 18-6.9 1.7 0 3.6 0 5.9.4V45.2c-2.4-.5-4.4-.8-6.4-.8zm31-8.8c9.6 0 17.3-7.9 17.3-17.5 0-9.7-7.7-17.5-17.3-17.5-9.7 0-17.5 7.7-17.5 17.5 0 9.6 7.7 17.5 17.5 17.5zm-16.7 10.3h33.2v103.7h-33.2V45.9zm127.3 9.3c-5.9-7.6-14-11.3-24.4-11.3-9.6 0-18 4-25.9 12.4l-1.7-10.4h-29.1V188l33.1-5.5v-33.3c5.1 1.6 10.3 2.4 14.9 2.4 8.3 0 20.3-2.1 29.6-12.3 8.9-9.7 13.5-24.8 13.5-44.7 0-17.5-3.4-30.9-10-39.4zm-27.5 64c-2.7 5.1-6.8 7.7-11.6 7.7-3.3 0-6.3-.7-8.9-2V75.6c5.6-5.9 10.7-6.5 12.5-6.5 8.4 0 12.5 9.1 12.5 26.8 0 10.1-1.4 18-4.5 23.3zm132.3-22.4c0-16.5-3.6-29.6-10.7-38.8-7.2-9.3-18-14.1-31.7-14.1-28.1 0-45.6 20.8-45.6 54.1 0 18.7 4.7 32.7 13.9 41.6 8.3 8 20.1 12 35.5 12 14.1 0 27.2-3.3 35.5-8.8l-3.6-22.7c-8.1 4.4-17.6 6.8-28.3 6.8-6.4 0-10.8-1.3-14-4.1-3.5-2.9-5.5-7.7-6.1-14.5h54.8c0-1.6.3-9.1.3-11.5zM396.2 88c.9-14.8 4.9-21.7 12.5-21.7 7.5 0 11.3 7.1 11.9 21.7h-24.4z"
    />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
