import { doFetchList } from '@/services/agent/audience.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'
import { normalizeCriteria } from '@/_helpers/queryCriteria'

export const audienceList = {
  namespaced: true,
  state: () => ({
    list: [],
    recordsCount: 0,
    inProgress: false,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.list = list
    },
    setListCount(state, number) {
      state.recordsCount = number
    },
  },
  actions: {
    fetchList: async ({ commit }, { artistId }) => {
      commit('inProgress', true)
      try {
        let list = await doFetchList(artistId)
        if (!Array.isArray(list)) list = []
        list = humps(list)
        list = list.map((m) => ({ ...m, criteria: normalizeCriteria(m.criteria) }))
        // list.forEach(e => {
        //   if (e.criteria) {
        //     // Why though?
        //     // This should be in normailzeCriteria and we should normalize criteria of each item in list here?
        //     // Ensure criteria renders without date. TODO: Pass subcriteria through a defaultCriteria, similar to main criteria above
        //     e.criteria.find(f => f.criteriaType === 'transaction' || f.criteria_type === 'transaction')?.criteria?.forEach(f => {
        //       if(f.fields.find(f2 => !(f2.field === 'date'))) {
        //         f.fields.push({field: 'date', from: null, to: null})
        //       }
        //     })
        //   }
        // })
        list = list.sort((a, b) => Number(b.pinned) - Number(a.pinned))
        commit('setListCount', list.length)
        commit('setList', list)
      } finally {
        commit('inProgress', false)
      }
    },
    resetList: async ({ commit }) => {
      commit('setList', [])
      commit('setListCount', 0)
    },
  },
}
