<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
    <g>
      <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
        <path
          d="M1891.4,4968.8c-361.4-118-658.8-422.8-774.4-801.4c-41.8-140.1-44.2-339.2-44.2-4044c0-3704.8,2.5-3903.9,44.2-4044c118-381,405.6-678.5,786.7-806.3c150-51.6,184.4-54.1,2229.8-61.5c1327.5-4.9,2153.5,2.4,2286.3,17.2c518.7,63.9,926.8,432.7,1030.1,931.7c17.2,86,27,612.1,27,1563.5c0,1413.6,0,1435.7-51.6,1499.6c-71.3,91-218.8,91-290.1,0c-49.2-63.9-51.6-90.9-51.6-1069.4v-1005.5H6053.4H5020.9l-78.7,150c-88.5,162.3-194.2,263.1-366.3,346.6c-159.8,76.2-435.1,76.2-602.3,0c-159.8-73.8-317.1-228.6-381-378.6l-51.6-118H2510.9H1478.4V184.8v3036.1h2802.6h2802.6V2166.3V1109.2l56.5-66.4c81.1-91,199.1-91,280.3,0l56.5,66.4v1423.4c0,944-9.8,1467.7-27,1553.7c-73.8,354-312.2,653.9-651.5,821.1l-191.8,95.9l-2286.3,4.9C2154.4,5013.1,2024.1,5010.6,1891.4,4968.8z M6628.7,4553.4c287.6-137.7,432.7-371.2,449.9-722.8l9.8-216.3H4280.9H1473.4l9.8,240.9c7.4,186.8,22.1,263,66.4,346.6c78.7,152.4,253.2,309.8,403.2,368.8c125.4,46.7,186.8,49.2,2328.1,49.2l2200.2,2.5L6628.7,4553.4z M4467.8-2740.7c216.3-140.1,206.5-499.1-17.2-614.6c-314.7-164.7-651.5,162.2-489.2,476.9C4052.3-2696.4,4300.6-2630,4467.8-2740.7z M3602.4-3379.8c181.9-366.3,649-521.2,1017.8-334.3c120.5,61.5,312.2,272.9,349.1,383.5l29.5,86h1044.8h1044.8l-9.8-275.3c-9.8-322-54.1-442.5-211.4-614.6c-120.5-130.3-275.3-211.4-464.6-240.9c-199.1-34.4-4056.3-32-4257.9,0c-191.8,32-319.6,95.9-445,221.3c-172.1,172.1-206.5,268-216.3,609.7l-9.8,299.9H2506h1032.5L3602.4-3379.8z"
        />
        <path
          style="fill: #39b442"
          d="M5719.1,1822.1c-39.3-24.6-445-417.9-897.3-872.7l-823.6-826l860.4-860.4c599.9-599.9,880.1-862.9,929.3-875.2c127.8-27,263,118,223.7,245.8c-7.4,27.1-292.6,331.9-631.8,673.6L4762.8-73.3h2020.8h2023.2l59,61.5c39.3,36.9,61.5,88.5,61.5,135.2c0,46.7-22.1,98.3-61.5,135.2l-59,61.5H6783.5H4760.3l626.9,626.9c582.6,582.6,624.4,631.8,624.4,712.9C6011.6,1819.7,5854.3,1908.1,5719.1,1822.1z"
        />
      </g>
    </g>
  </svg>
</template>
