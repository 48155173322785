import { getField, updateField } from 'vuex-map-fields'
// import humps from 'lodash-humps'

import {
  twilioListComplianceBundle,
  twilioCreateComplianceBundle,
  twilioDeleteComplianceBundle,
  twilioUpdateComplianceBundle,
  twilioListTollFreeVerifications,
  twilioCreateTollFreeVerification,
  twilioDeleteTollFreeVerification,
} from '@/services/agent/twilio.service'

export const smsStatusStates = [
  'none',
  'unverified',
  'draft',
  'pending',
  'in-review',
  'rejected',
  'approved',
]

export const phoneVerification = {
  namespaced: true,
  state: () => ({
    listUK: [],
    loadingListUK: false,
    refreshingListUK: false,
    createOneUKInProgress: false,
    deleteOneUKInProgress: false,
    oneUK: null,
    oneUKInquiry: {
      inquiryId: null,
      inquirySessionToken: null,
    },

    listUS: [],
    loadingListUS: false,
    refreshingListUS: false,
    createOneUSInProgress: false,
    deleteOneUSInProgress: false,
    oneUS: null,
    oneUSValid: true,
    oneUSInquiry: {
      inquiryId: null,
      inquirySessionToken: null,
      registrationId: null,
    }
  }),
  getters: {
    getField,
    oneUSRegId: (state) => {
      if(!state.oneUSInquiry?.registrationId) {
        return ''
      }
      return state.oneUSInquiry.registrationId
    },
  },
  mutations: {
    updateField,
    listUK(state, list = []) {
      state.listUK = list || []
    },
    loadingListUK(state, yesOrNo = false) {
      state.loadingListUK = yesOrNo
    },
    refreshingListUK(state, yesOrNo = false) {
      state.refreshingListUK = yesOrNo
    },
    createOneUKInProgress(state, yesOrNo = false) {
      state.createOneUKInProgress = yesOrNo
    },
    deleteOneUKInProgress(state, yesOrNo = false) {
      state.deleteOneUKInProgress = yesOrNo
    },
    oneUK(state, one = null) {
      state.oneUK = one || null
    },
    oneUKInquiry(state, inquiry = { inquiryId: null, inquirySessionToken: null }) {
      state.oneUKInquiry = inquiry || { inquiryId: null, inquirySessionToken: null }
    },

    listUS(state, list = []) {
      state.listUS = list || []
    },
    loadingListUS(state, yesOrNo = false) {
      state.loadingListUS = yesOrNo
    },
    refreshingListUS(state, yesOrNo = false) {
      state.refreshingListUS = yesOrNo
    },
    createOneUSInProgress(state, yesOrNo = false) {
      state.createOneUSInProgress = yesOrNo
    },
    deleteOneUSInProgress(state, yesOrNo = false) {
      state.deleteOneUSInProgress = yesOrNo
    },
    oneUS(state, one = null) {
      state.oneUS = one || null
    },
    oneUSValid(state, yesOrNo = false) {
      state.oneUSValid = yesOrNo || false
    },
    oneUSInquiry(state, inquiry = { inquiryId: null, inquirySessionToken: null, registrationId: null }) {
      state.oneUSInquiry = inquiry || {
        inquiryId: null,
        inquirySessionToken: null,
        registrationId: null,
      }
    },
  },
  actions: {
    fetchListUK: async ({ commit, rootState }) => {
      let success = false
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      commit('loadingListUK', true)
      try {
        const resp = await twilioListComplianceBundle(rootState.artistOne.one.id)
        if(resp?.status === 'ERROR' || !Array.isArray(resp)) {
          throw new Error(resp?.message)
        }
        // commit('listUK', humps(resp))
        commit('listUK', resp)
        success = true
      } catch (error) {
        console.log(error?.message || error || 'Could not fetch bundles list')
        commit('listUK', [])
      }
      commit('loadingListUK')
      return success
    },
    refreshListUK: async ({ commit, dispatch }) => {
      commit('refreshingListUK', true)
      const success = await dispatch('fetchListUK')
      commit('refreshingListUK')
      return success
    },
    selectOneUK: ({commit}, one = null) => {
      commit('oneUK', one)
      commit('oneUKInquiry', { inquiryId: null, inquirySessionToken: null })
    },
    findAndSelectOneUK: ({ state, dispatch }, bundleSid = null) => {
      if(!bundleSid) return
      const found = state.listUK?.find(f => f.sid === bundleSid)
      if(!found) return
      dispatch('selectOneUK', found)
    },
    createOneUK: async ({ commit, dispatch, rootState }) => {
      let success = false
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      commit('createOneUKInProgress', true)

      let bundleSid = null
      try {
        const resp = await twilioCreateComplianceBundle(rootState.artistOne.one.id, 'UK number')
        if(resp?.status === 'ERROR') throw new Error(resp?.message)
        if(!resp?.registration_id) throw new Error('')
        bundleSid = resp.registration_id.split('registration.')[1]
        await dispatch('refreshListUK')
        if(bundleSid) dispatch('findAndSelectOneUK', bundleSid)
        success = true
      } catch (error) {
        console.log(error)
        success = false
      }

      commit('createOneUKInProgress')
      return success
    },
    deleteOneUK: async ({ commit, rootState }, sid) => {
      let success = false
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      if (!sid) {
        console.log('No sid')
        return success
      }
      commit('deleteOneUKInProgress', true)

      try {
        const resp = await twilioDeleteComplianceBundle(rootState.artistOne.one.id, sid)
        if(resp?.status === 'ERROR') throw new Error(resp?.message)
        success = true
      } catch (error) {
        console.log(error)
        success = false
      }

      commit('deleteOneUKInProgress')
      return success
    },
    updateOneUKInquiry: async ({ commit, rootState}, registrationId = null) => {
      let success = false
      commit('oneUKInquiry', { inquiryId: null, inquirySessionToken: null })

      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      if (!registrationId) {
        console.log('No registration Id')
        return success
      }

      try {
        const resp = await twilioUpdateComplianceBundle(
          rootState.artistOne.one.id,
          registrationId,
        )
        if(!resp?.inquiry_id || !resp?.inquiry_session_token || resp?.status === 'ERROR' || resp?.status === 400 || resp?.status === 500) {
          throw new Error(resp?.message)
        }
        commit('oneUKInquiry', {
          inquiryId: resp?.inquiry_id || null,
          inquirySessionToken: resp?.inquiry_session_token || null,
        })
        success = true
      } catch (error) {
        console.log(error)
      }

      return success
    },

    fetchListUS: async ({ commit, rootState }) => {
      let success = false
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      if (!rootState.artistOne?.one?.smsPhoneNumberUs) {
        console.log('No US phone number')
        return success
      }
      commit('loadingListUS', true)
      try {
        const resp = await twilioListTollFreeVerifications(rootState.artistOne.one.id)
        if(resp?.status === 'ERROR' || !Array.isArray(resp)) {
          throw new Error(resp?.message)
        }
        commit('listUS', resp)
        commit('oneUSValid', true)
        success = true
      } catch (error) {
        console.log(error?.message || error || 'Could not fetch toll-free verifications list')
        if(error?.message && error.message?.startsWith('sid not found for number')) {
          commit('oneUSValid', false)
        }
        else {
          commit('oneUSValid', true)
        }
        commit('listUS')
      }
      commit('loadingListUS')
      return success
    },
    refreshListUS: async ({ commit, dispatch }) => {
      commit('refreshingListUS', true)
      const response = await dispatch('fetchListUS')
      commit('refreshingListUS')
      return response
    },
    createOneUS: async ({
      commit,
      rootState,
    }) => {
      let success = false
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      commit('createOneUSInProgress', true)

      try {
        const resp = await twilioCreateTollFreeVerification(rootState.artistOne.one.id)
        if(resp?.status === 'ERROR') throw new Error(resp?.message)
        if(!resp?.registration_id) throw new Error('no regid')
        commit('oneUSInquiry', {
          inquiryId: resp?.inquiry_id || null,
          inquirySessionToken: resp?.inquiry_session_token || null,
          registrationId: resp?.registration_id || null,
        })
        success = true
      } catch (error) {
        console.log(error)
        success = false
      }

      commit('createOneUSInProgress')
      return success
    },
    resetOneUSInquiry: ({ commit }) => {
      commit('oneUSInquiry', {
        inquiryId: null,
        inquirySessionToken: null,
        registrationId: null,
      })
    },
    deleteOneUS: async ({ commit, rootState }, sid) => {
      let success = false
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return success
      }
      if (!sid) {
        console.log('No sid')
        return success
      }
      commit('deleteOneUSInProgress', true)

      try {
        const resp = await twilioDeleteTollFreeVerification(rootState.artistOne.one.id, sid)
        if(resp?.status === 'ERROR') throw new Error(resp?.message)
        success = true
      } catch (error) {
        console.log(error)
        success = false
      }

      commit('deleteOneUSInProgress')
      return success
    },
  },
}
