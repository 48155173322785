<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18">
    <path d="M9.5 0C4.53742 0 0.5 4.03742 0.5 9C0.5 13.9622 4.53742 18 9.5 18C14.4622 18 18.5 13.9622 18.5 9C18.5 4.03742 14.4629 0 9.5 0ZM11.7382 9.31684H10.274V14.5357H8.10428C8.10428 14.5357 8.10428 11.6841 8.10428 9.31684H7.07291V7.47233H8.10428V6.27926C8.10428 5.4248 8.51031 4.08962 10.2939 4.08962L11.9017 4.09579V5.88629C11.9017 5.88629 10.9247 5.88629 10.7348 5.88629C10.5448 5.88629 10.2747 5.98127 10.2747 6.38874V7.47269H11.9278L11.7382 9.31684Z"/>
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
