import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth'
import { wrapRequest, wrapCsvRequest, wrapRequestNoError } from '../nav'

export const fanQueryLimit = 2000

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/fan?id=eq.${id}`, requestOptions)
})

export const doSendFanSms = wrapRequest(({ artistId, fanId, text }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, fanId, text }),
  }
  return fetch(`${openstageApi2Url}/sendFanSms`, requestOptions)
})

export const sendFanEmail = wrapRequest(({ artistId, fanId, subject, text }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, fanId, subject, text }),
  }
  return fetch(`${openstageApi2Url}/sendFanEmail`, requestOptions)
})

export const doPostOne = wrapRequest((fan) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(fan),
  }
  return fetch(`${openstageApiCrudUrl}/fan?id=eq.${fan.id}`, requestOptions)
})

export const doDeleteOne = wrapRequest((fanId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ fanId: fanId }),
  }
  return fetch(`${openstageApi2Url}/deleteFan`, requestOptions)
})

export const doPatchOne = wrapRequest((fan) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(fan),
  }
  return fetch(`${openstageApiCrudUrl}/fan?id=eq.${fan.id}`, requestOptions)
})

export const doFetchArtistFanTags = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }

  return fetch(`${openstageApi2Url}/artistTags`, requestOptions)
})

export const doTagFans = wrapRequest((artistId, fans, tags) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, fanIds: fans, tags }),
  }

  return fetch(`${openstageApi2Url}/tagFans`, requestOptions)
})

export const doQueryFanbase = wrapRequest(
  (artistId, criteria, paging, sorting, limit = null, seed = null) => {
    const body = {
      artistId,
      criteria,
      paging,
      sorting,
    }
    if (limit) body.limit = limit
    if (seed) body.seed = seed
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify(body),
    }
    return fetch(`${openstageApi2Url}/fanQuery`, requestOptions)
  },
)

export const doQueryDownload = wrapCsvRequest((artistId, criteria, limit = null, seed = null) => {
  const body = {
    artistId,
    criteria,
  }
  if (limit) body.limit = limit
  if (seed) body.seed = seed
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(body),
  }
  return fetch(`${openstageApi2Url}/fanQueryDownload`, requestOptions)
})

export const doQueryFanbaseSummary = wrapRequest(
  (artistId, criteria, paging, sorting, broadcastId = null, signal = null) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({ artistId, criteria, paging, sorting, broadcastId }),
      signal,  // Pass the signal to fetch options
    }
    return fetch(`${openstageApi2Url}/fanQuerySummary`, requestOptions)
  },
)

export const updateActivity = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/updateActivity`, requestOptions)
})

export const doFetchFanHistory = wrapRequest((id, artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(
    `${openstageApiCrudUrl}/fan_history?fan_id=${id}&artist_id=${artistId}`,
    requestOptions,
  )
})

export const fanQueryCountrySummary = wrapRequest((artistId, criteria) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, criteria }),
  }
  return fetch(`${openstageApi2Url}/fanQueryCountrySummary`, requestOptions)
})

export const countryCallingCodesSummary = wrapRequest((artistId, criteria) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, criteria }),
  }
  return fetch(`${openstageApi2Url}/countryCallingCodesSummary`, requestOptions)
})

export const lookupLocation = wrapRequest((artistId, location) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, location }),
  }
  return fetch(`${openstageApi2Url}/lookupLocation`, requestOptions)
})

export const fanEngagement = wrapRequest((artistId, fanId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, fanId }),
  }
  return fetch(`${openstageApi2Url}/fanEngagement`, requestOptions)
})

export const doCancelSubscription = wrapRequest((fanId = null, artistId = null) => {
  if (!fanId || !artistId) {
    throw new Error('Could not cancel fan subscription, please reload and retry.')
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, fanId }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/adminCancelSubscription`, requestOptions)
})

export const doDashboardStats = wrapRequest((query) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApi2Url}/dashboard?${query}`, requestOptions)
})

export const propensityToPurchase = wrapRequestNoError((artistId, fanId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, fanId }),
  }
  return fetch(`${openstageApi2Url}/propensityToPurchase`, requestOptions)
})

export const doFunnel = wrapRequestNoError((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/funnel`, requestOptions)
})

export const fanQueryCityTierSummary = wrapRequest((artistId, criteria, cityTiers, countries) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, criteria, cityTiers, countries }),
  }
  return fetch(`${openstageApi2Url}/fanQueryCityTierSummary`, requestOptions)
})