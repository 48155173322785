import { doSummary as fetchTransactions } from '@/services/agent/fan_transaction.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'

export const transactionList = {
  namespaced: true,
  state: () => ({
    list: [],
    // selected: [],
    inProgress: false,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo
    },
    setList(state, list = []) {
      state.list = list
    },
  },
  actions: {
    fetchList: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('inProgress', true)
      try {
        const list = await fetchTransactions(rootState.artistOne.one.id)

        if (!list.length || list?.status === 'ERROR') {
          throw new Error('Could not load transaction list')
        }
        commit('setList', humps(list))
      } catch (error) {
        commit('setList')
      }
      commit('inProgress')
    },
  },
}
