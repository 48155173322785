<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 75 65"
  >
    <g>
      <g>
        <path
          d="M64.396,38.297c-0.356,0-0.714-0.133-0.992-0.401l-9.407-9.042c-0.417-0.401-0.553-1.017-0.34-1.557
					c0.209-0.54,0.725-0.898,1.306-0.91l3.45-0.069c-2.586-8.246-9.784-14.435-18.416-15.639c-1.019-0.143-2.057-0.215-3.083-0.215
					c-8.91,0-17.007,5.23-20.628,13.325c-0.155,0.347-0.442,0.617-0.798,0.753c-0.355,0.136-0.75,0.124-1.096-0.03l-6.936-3.104
					c-0.723-0.324-1.046-1.172-0.723-1.895C12.038,7.659,23.888,0,36.92,0c1.505,0,3.025,0.106,4.518,0.314
					c13.612,1.898,24.848,12.423,27.726,25.793l4.253-0.083c0.564-0.024,1.111,0.328,1.342,0.859c0.234,0.533,0.122,1.151-0.278,1.569
					l-9.051,9.404C65.149,38.148,64.774,38.297,64.396,38.297z M58.48,29.186l5.877,5.649l5.655-5.876l-1.997,0.039
					c-0.699,0.03-1.317-0.491-1.439-1.187C64.344,15.08,53.846,4.94,41.042,3.155c-1.36-0.19-2.748-0.287-4.122-0.287
					c-11.422,0-21.85,6.438-26.955,16.521l4.324,1.936C18.627,12.94,27.355,7.597,36.913,7.597c1.159,0,2.331,0.081,3.477,0.241
					c10.396,1.45,18.946,9.31,21.28,19.559c0.097,0.421-0.003,0.862-0.267,1.201c-0.266,0.341-0.672,0.542-1.103,0.551L58.48,29.186z"
        />
        <path
          d="M38.077,65c-1.506,0-3.025-0.104-4.517-0.313c-13.609-1.9-24.845-12.423-27.727-25.791l-4.252,0.08
					c-0.561-0.001-1.109-0.329-1.342-0.858c-0.231-0.532-0.121-1.15,0.282-1.568l9.05-9.406c0.548-0.57,1.457-0.586,2.027-0.04
					l9.406,9.046c0.418,0.4,0.554,1.016,0.343,1.555c-0.212,0.541-0.727,0.899-1.307,0.912l-3.45,0.068
					c2.583,8.246,9.782,14.436,18.416,15.641c1.018,0.14,2.055,0.212,3.084,0.212c8.906,0,17.004-5.228,20.626-13.323
					c0.154-0.346,0.442-0.618,0.796-0.754c0.358-0.135,0.753-0.124,1.099,0.029l6.937,3.107c0.345,0.154,0.617,0.442,0.753,0.796
					c0.136,0.354,0.124,0.75-0.03,1.099C62.962,57.343,51.112,65,38.077,65z M7.011,36.003c0.694,0,1.292,0.499,1.413,1.186
					c2.232,12.732,12.734,22.871,25.535,24.659c1.36,0.189,2.746,0.287,4.119,0.287c11.424,0,21.852-6.439,26.956-16.522l-4.321-1.937
					c-4.34,8.386-13.068,13.729-22.622,13.729c-1.161,0-2.332-0.082-3.481-0.241c-10.394-1.45-18.944-9.31-21.278-19.559
					c-0.096-0.421,0.003-0.863,0.269-1.201c0.265-0.341,0.67-0.544,1.101-0.553l1.823-0.034l-5.878-5.652l-5.656,5.877l1.995-0.038
					C6.993,36.003,7.001,36.003,7.011,36.003z"
        />
      </g>
    </g>
  </svg>
</template>
