<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.71 23.99">
    <path
      d="M21.3.07 4.45 0A4.435 4.435 0 0 0 0 4.22c0 2.31 2.43 4.63 10.63 5.67 6.26.87 10.03 2.39 11.9 5l2.95-9.02C26.42 3.02 24.3.08 21.3.07Zm-.4 19.84c.87-3.52-2-5.88-11.66-7.18-3.09-.37-5.54-.99-7.42-1.9l3.19 10.04a4.434 4.434 0 0 0 4.2 3.09l7.13.03c1.13 0 3.66-.46 4.57-4.08h-.01Z"
    />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
