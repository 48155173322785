import { doFetchListByArtist, doLookup } from '@/services/agent/email_master_template.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'

export const emailMasterTemplateList = {
  namespaced: true,
  state: () => ({
    list: [],
    selected: [],
    inProgress: false,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.selected = []
      for (let i in state.list) {
        state.selected.push(false)
        state.selected[i] = false
      }
      state.list = list
    },
  },
  actions: {
    fetchList: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('inProgress', true)
      const list = await doFetchListByArtist(rootState.artistOne.one.id)
      if (list && list?.status !== 'ERROR') {
        commit('setList', humps(list))
      }
      commit('inProgress')
    },
    lookUpList: async ({ commit, rootState }, { search }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('inProgress', true)
      try {
        const list = await doLookup(search, rootState.artistOne.one.id)
        if (list) {
          commit('setList', humps(list))
        }
      } finally {
        commit('inProgress')
      }
    },
  },
}
