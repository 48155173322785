import { authHeader } from './auth'
const openstageApi2Url = import.meta.env.VITE_OPENSTAGE_API2_URL

export const registrationService = {
  forgotPassword,
  resetPassword,
  adminChangePassword,
}

function forgotPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      returnUrl: `${window.location.origin}/gate/reset-password/openstage`,
    }),
  }
  return fetch(`${openstageApi2Url}/forgotPassword`, requestOptions).then(handleResponse)
}

function resetPassword(password, token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      password: password,
    }),
  }
  return fetch(`${openstageApi2Url}/changeUserPassword`, requestOptions).then(handleResponse)
}

function adminChangePassword(organizationId, userId, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ organizationId, userId, password }),
  }
  return fetch(`${openstageApi2Url}/adminChangeUserPassword`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    } else {
      return data
    }
  })
}
