import { doPatchOne, doPostOne, doFetchOne } from '@/services/agent/fan_artist.service'
import { sanitizeTags } from '@/services/util.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'

export const fanArtistOne = {
  namespaced: true,
  state: () => ({
    oneFanArtist: {},
    inProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.oneFanArtist = one
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
  },
  actions: {
    fetchOne: async ({ commit }, { fanId, artistId }) => {
      commit('inProgress', true)
      const fans = await doFetchOne(artistId, fanId)
      if (fans && fans.length > 0) {
        //Remove duplicate and invalid tags
        if (fans[0].tags && fans[0].tags.length) {
          fans[0].tags = sanitizeTags(fans[0].tags)
        }
        commit('setOne', humps(fans[0]))
      }
      commit('inProgress', false)
    },
    save: async ({ commit, state }, fanArtist) => {
      commit('inProgress', true)
      try {
        const {
          receivesEmail,
          receivesSms,
          receivesWhatsapp,
          consentSms,
          consentEmail,
          tags,
          updatedAt,
        } = state.oneFanArtist
        if (undefined === state.oneFanArtist.id) {
          const fans = await doPostOne(fanArtist)
          commit('setOne', humps(fans))
          commit('inProgress', false)
          return { new: true }
        } else {
          const fans = await doPatchOne(state.oneFanArtist.id, {
            receives_email: receivesEmail,
            receives_sms: receivesSms,
            receives_whatsapp: receivesWhatsapp,
            consent_sms: consentSms,
            consent_email: consentEmail,
            tags,
            updated_at: updatedAt,
          })
          commit('setOne', humps(fans))
          commit('inProgress', false)
          return { update: true }
        }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    setOne: async ({ commit }, fan) => {
      commit('setOne', humps(fan))
    },
    saveTags: async ({ commit, state }) => {
      commit('inProgress', true)
      try {
        await doPatchOne(state.oneFanArtist.id, { tags: state.oneFanArtist.tags })
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    resetOne: async ({ commit }) => {
      commit('setOne', {})
    },
  },
}
