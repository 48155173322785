import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth'
import { wrapRequest } from '../nav'

export const doFetchList = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  }

  return fetch(`${openstageApiCrudUrl}/audience?artist_id=eq.${artistId}`, requestOptions)
})

export const doPostOne = wrapRequest((audience) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(audience),
  }

  return fetch(`${openstageApiCrudUrl}/audience`, requestOptions)
})

export const doDeleteOne = (audienceId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/audience?id=eq.${audienceId}`, requestOptions)
}

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/audience?id=eq.${id}`, requestOptions)
})

export const doPatchOne = wrapRequest((audience) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(audience),
  }
  return fetch(`${openstageApiCrudUrl}/audience?id=eq.${audience.id}`, requestOptions)
})

export const doFetchAudienceHistory = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/audience_history?audience_id=${id}`, requestOptions)
})
