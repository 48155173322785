import { doFetchOne, doPostOne, doPatchOne, doDeleteOne } from '@/services/agent/audience.service'
import { getField, updateField } from 'vuex-map-fields'
import { normalizeCriteria } from '@/_helpers/queryCriteria'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { snakeCase } from 'lodash'
const snakes = createHumps(snakeCase)

export const audienceOne = {
  namespaced: true,
  state: () => ({
    one: {
      criteria: [],
    },
    inProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = {
        ...one,
        criteria: normalizeCriteria(one?.criteria),
      }
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setCriteria(state, newCriteria) {
      state.one.criteria = normalizeCriteria(newCriteria)
    },
  },
  actions: {
    fetchOne: async ({ commit, dispatch }, { audienceId }) => {
      commit('inProgress', true)
      try {
        const list = await doFetchOne(audienceId)
        const one = humps(list[0])
        dispatch('setOne', one)
        commit('inProgress', false)
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    save: async ({ commit, dispatch, state }, extraData) => {
      commit('inProgress', true)
      try {
        if (undefined === state.one.id) {
          const newAudience = await doPostOne(snakes(Object.assign({}, state.one, extraData)))
          const list = await doFetchOne(newAudience.id)
          const one = humps(list[0])
          dispatch('setOne', one)
          commit('inProgress', false)
          return { new: true }
        } else {
          await doPatchOne(snakes(Object.assign({}, state.one, extraData)))
          const list = await doFetchOne(state.one.id)
          const one = humps(list[0])
          dispatch('setOne', one)
          commit('inProgress', false)
          return { update: true }
        }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    create: async ({ commit, dispatch }, data) => {
      commit('inProgress', true)
      try {
        const newAudience = await doPostOne(snakes(data))
        const list = await doFetchOne(newAudience.id)
        const one = humps(list[0])
        dispatch('setOne', one)
        commit('inProgress', false)
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    delete: async ({ commit, dispatch, state }) => {
      commit('inProgress', true)
      try {
        await doDeleteOne(state.one.id)
        dispatch('setOne', {})
        commit('inProgress', false)
        return { removed: true }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    setOne: async ({ commit, dispatch }, audience) => {
      commit('setOne', humps(audience))
      dispatch('fanList/initializeActiveCriteria', audience.criteria || [], { root: true })
    },
    resetOne: async ({ commit, dispatch }) => {
      commit('setOne', {})
      dispatch('fanList/resetCriteria', {}, { root: true })
    },
    setCriteria: async ({ commit }, newCriteria) => {
      commit('inProgress', true)
      commit('setCriteria', newCriteria)
      commit('inProgress', false)
    },
  },
}
