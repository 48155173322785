import { getField, updateField } from 'vuex-map-fields'
// import moment from 'moment'

export const activityFilter = {
  namespaced: true,
  state: () => ({
    search: '',
    accountManagerId: null,
    dateFrom: null,
    dateTo: null,
  }),
  getters: {
    getField,
    currentFilterCount: (state) => {
      return (
        state.accountManagerId ? 1 : 0) +
        (state.dateFrom ? 1 : 0) +
        (state.dateTo ? 1 : 0)
    },
  },
  mutations: {
    updateField,
    search(state, val = '') {
      state.search = val
    },
    accountManagerId(state, val = null) {
      state.accountManagerId = val
    },
    dateFrom(state, val = null) {
      state.dateFrom = val
    },
    dateTo(state, val = null) {
      state.dateTo = val
    },
  },
  actions: {
    reset: ({ commit }) => {
      commit('search')
      commit('accountManagerId')
      commit('dateFrom')
      commit('dateTo')
    },
  },
}
