import { doFetchOne, doPostOne, doPatchOne } from '@/services/agent/email_master_template.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { snakeCase } from 'lodash'
const snakes = createHumps(snakeCase)
import { toastification } from '@/plugins/toastification'

export const emailMasterTemplateOne = {
  namespaced: true,
  state: () => ({
    one: {},
    inProgress: true,
    error: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one = {}) {
      state.one = one
    },
    inProgress(state, bool = false) {
      state.inProgress = bool
    },
    error(state, bool = false) {
      state.error = bool
    },
  },
  actions: {
    setOne: ({ commit }, template = {}) => {
      commit('setOne', humps(template))
    },
    new: async ({ commit }) => {
      commit('inProgress', true)
      try {
        const response = await fetch('https://tlapi.github.io/topol-editor/templates/0.json')
        const template = await response.text()
        commit('setOne', {
          title: '',
          description: '',
          status: 'draft',
          contentHtmlFull: '',
          topolJson: template,
        })
        commit('error')
      } catch (error) {
        const errorMessage = 'Failed to fetch blank TOPOL template'
        toastification.error(errorMessage, { timeout: false })
        commit('error', true)
      }
      commit('inProgress')
    },
    fetchOne: async ({ commit }, emailMasterTemplateId) => {
      try {
        if (!emailMasterTemplateId) {
          throw new Error('Email template not found, no id given')
        }
        commit('inProgress', true)
        const emailTemplates = await doFetchOne(emailMasterTemplateId)
        if (emailTemplates?.status === 'ERROR' || !emailTemplates?.length) {
          throw new Error('Failed to fetch blank TOPOL template')
        }
        commit('setOne', humps(emailTemplates[0]))
        commit('inProgress')
      } catch (err) {
        commit('setOne')
        commit('inProgress')
        // throw err
      }
    },
    save: async ({ commit, state, dispatch }, emailTemplate) => {
      commit('inProgress', true)
      let returnValue = {}
      try {
        if (emailTemplate.new) {
          // clone
          delete emailTemplate.new
          // emailTemplate.title += '-copy'
          const template = await doPostOne(snakes(emailTemplate))
          if (template) {
            commit('setOne', humps(template))
            commit('inProgress')
            returnValue = { new: true }
          } else {
            throw 'Failed to clone email template'
          }
        } else if (undefined === state.one.id) {
          // new
          const template = await doPostOne(snakes(Object.assign({}, state.one, emailTemplate)))
          if (template) {
            commit('setOne', humps(template))
            commit('inProgress')
            returnValue = { new: true }
          } else {
            throw 'Failed to create email template'
          }
        } else {
          // update
          const template = await doPatchOne(snakes(Object.assign({}, state.one, emailTemplate)))
          if (template) {
            commit('setOne', humps(template))
            commit('inProgress')
            returnValue = { update: true }
          } else {
            throw 'Failed to save email template'
          }
        }
        dispatch('emailMasterTemplateList/fetchList', null, { root: true })
        return returnValue
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    archive: async ({ commit, state }, archive = false) => {
      commit('inProgress', true)
      try {
        const template = await doPatchOne(
          snakes({ ...state.one, status: archive ? 'archived' : 'draft' }),
        )
        commit('setOne', humps(template))
        commit('inProgress')
        return { archive: true }
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    resetOne: ({ commit }) => {
      commit('setOne')
    },
  },
}
