<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18">
    <path d="m5.6 4.4 6.4 9.2h1.4L7 4.4z"/>
    <path d="M9.5 0c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm2.1 14.3-2.8-4.1-3.5 4.1h-1l4-4.7-4-5.9h3.1l2.7 3.9 3.3-3.9h.9l-3.9 4.5 4.2 6.1h-3z"/>
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
