import gateService from '../services/gate.service'
import adminRoles, { isAdmin } from '@/_helpers/admin.js'
import { getAuthUser, setAuthUser, resetAuthUser } from '../services/auth'

export const authStoreModule = {
  namespaced: true,
  state: () => ({
    inProgress: false,
    userAuth: getAuthUser() || {},
    signInMessage: null,
    errorMessage: null,
  }),
  getters: {
    userAuth: (state) => {
      return state?.userAuth || null
    },
    userAuthEmail: (state) => {
      return state?.userAuth?.email || null
    },
    isUser: (state) => {
      return !!state?.userAuth?.role
    },
    isAdmin: (state) => {
      return isAdmin(state?.userAuth?.role)
    },
    isArtistAdmin: (state) => {
      return state?.userAuth?.role === adminRoles.artistAdmin
    },
    isOrganizationAdmin: (state) => {
      return state?.userAuth?.role === adminRoles.organizationAdmin
    },
    isSuperAdmin: (state) => {
      return state?.userAuth?.role === adminRoles.superAdmin
    },
    isSuperAdminWithDemo: (state) => {
      return !localStorage.getItem('mode') && state?.userAuth?.role === adminRoles.superAdmin
    },
  },
  mutations: {
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo
    },
    errorMessage(state, message = '') {
      state.errorMessage = message
    },
    signInMessage(state, message = '') {
      state.signInMessage = message
    },
    userAuth(state, userAuth = {}) {
      state.userAuth = userAuth
    },
  },
  actions: {
    login: async ({ dispatch }, { username, password, newUser }) => {
      dispatch('prepLogin')
      try {
        const user = await gateService.doLogin(username, password)
        if (!user) throw new Error('Could not find user. Please try again.')
        if (user.auth) {
          dispatch('loginSuccess', user)
          if (newUser) {
            return user
          }
        }
        else {
          dispatch('loginFailure',
            user.message === 'user not found'
              ? 'No user for that email address and password'
              : user.message
          )
        }
      } catch (err) {
        console.log(err)
        dispatch('loginFailure', err.message)
      }
    },
    loginRefresh: async ({ dispatch }, { auth, artistId }) => {
      dispatch('prepLogin')
      try {
        const user = await gateService.doLoginRefresh(auth, artistId)
        if (user.auth) {
          dispatch('loginSuccess', user)
        } else {
          dispatch('loginFailure', user.message)
        }
      } catch (err) {
        dispatch('loginFailure', err.message)
      }
    },
    logout({ commit }) {
      commit('inProgress', true)
      commit('userAuth', {})
      resetAuthUser()
      commit('inProgress')
    },
    prepare({ commit }, message) {
      commit('inProgress')
      commit('signInMessage', message || null)
      commit('userAuth', {})
    },
    resetState({ commit }) {
      commit('inProgress')
      commit('userAuth', getAuthUser() || {})
      commit('errorMessage')
      commit('signInMessage')
    },
    loginSuccess({ commit }, user) {
      commit('inProgress')
      commit('errorMessage')
      commit('userAuth', user)
      setAuthUser(user)
    },
    loginFailure({ commit }, errorMessage) {
      commit('inProgress')
      commit('errorMessage', errorMessage || null)
      commit('userAuth', {})
    },
    prepLogin({ commit }) {
      commit('inProgress', true)
      commit('errorMessage')
      commit('signInMessage')
    },
  },
}
