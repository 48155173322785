import { authHeader, openstageApiCrudUrl } from '../auth'
import { wrapRequest } from '../nav'

export const doFetchOne = wrapRequest((templateId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/email_master_template?id=eq.${templateId}`, requestOptions)
})

export const doFetchListByArtist = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  }
  return fetch(
    `${openstageApiCrudUrl}/email_master_template?artist_id=eq.${artistId}`,
    requestOptions,
  )
})

export const doLookup = wrapRequest((searchTerm, artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  }
  return fetch(
    `${openstageApiCrudUrl}/email_master_template?title=ilike.*${searchTerm}*&artist_id=eq.${artistId}`,
    requestOptions,
  )
})

export const doPostOne = wrapRequest((email_master_template) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(email_master_template),
  }
  return fetch(`${openstageApiCrudUrl}/email_master_template`, requestOptions)
})

export const doPatchOne = wrapRequest((email_master_template) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(email_master_template),
  }
  return fetch(
    `${openstageApiCrudUrl}/email_master_template?id=eq.${email_master_template.id}`,
    requestOptions,
  )
})
