import { doPatchOne, doFetchOne } from '@/services/agent/discord_guild.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'

export const discordGuildOne = {
  namespaced: true,
  state: () => ({
    oneDiscordGuild: {},
    inProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.oneDiscordGuild = one
    },
    updateOne(state, one) {
      state.oneDiscordGuild = { ...state.oneDiscordGuild, ...one }
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
  },
  actions: {
    fetchOne: async ({ commit }, { artistId, guildId }) => {
      commit('inProgress', true)
      const discordGuild = await doFetchOne(artistId, guildId)
      if (discordGuild && discordGuild.id) {
        commit('setOne', humps(discordGuild))
      } else {
        commit('setOne', {})
      }
      commit('inProgress', false)
    },

    save: async ({ commit, state }, { discordGuild }) => {
      commit('inProgress', true)
      try {
        if (undefined === state.oneDiscordGuild) {
          throw 'Creation not supported'
        } else {
          const guild = await doPatchOne(discordGuild)
          commit('updateOne', humps(guild))
          commit('inProgress', false)
          return { update: true }
        }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
  },
}
