import { registrationService } from '@/services/registration.service'
import { getField, updateField } from 'vuex-map-fields'

export const registration = {
  namespaced: true,
  state: () => ({
    inProgress: false,
    webDomain: null,
    firstName: null,
    lastName: null,
    verifiedDomain: null,
    errorMessage: null,
    email: null,
    password: null,
    resetPasswordMessage: null,
    resetPasswordError: null,
    confirmEmailInProgress: false,
    confirmEmailMessage: null,
    confirmEmailErrorMessage: null,
    forgotPasswordMessage: null,
    forgotPasswordError: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    domainVerified(state, domain) {
      state.errorMessage = null
      state.verifiedDomain = domain
    },
    errorMessage(state, errorMessage) {
      state.errorMessage = errorMessage
    },
    resetPasswordMessage(state, resetPasswordMessage) {
      state.resetPasswordMessage = resetPasswordMessage
    },
    resetPasswordError(state, resetPasswordError) {
      state.resetPasswordError = resetPasswordError
    },
    resetState(state) {
      ;(state.inProgress = false),
        (state.webDomain = null),
        (state.firstName = null),
        (state.lastName = null),
        (state.verifiedDomain = null),
        (state.email = null),
        (state.password = null)
    },
    confirmEmailInProgress(state, yesOrNo) {
      state.confirmEmailInProgress = yesOrNo
    },
    confirmEmailMessage(state, message) {
      state.confirmEmailErrorMessage = null
      state.confirmEmailMessage = message
    },
    confirmEmailErrorMessage(state, message) {
      state.confirmEmailErrorMessage = message
      state.confirmEmailMessage = null
    },
    forgotPasswordMessage(state, forgotPasswordMessage) {
      state.forgotPasswordMessage = forgotPasswordMessage
    },
    forgotPasswordError(state, forgotPasswordError) {
      state.forgotPasswordError = forgotPasswordError
    },
  },
  actions: {
    forgotPassword({ commit }, { email }) {
      commit('inProgress', true)
      registrationService.forgotPassword(email).then(
        (data) => {
          commit('inProgress', false)
          commit('forgotPasswordMessage', data.message)
        },
        (error) => {
          commit('inProgress', false)
          commit('forgotPasswordError', error)
        },
      )
    },
    resetPassword: async ({ commit }, { password, token }) => {
      try {
        commit('inProgress', true)
        const data = await registrationService.resetPassword(password, token)
        commit('inProgress', false)
        return data
      } catch (error) {
        commit('inProgress', false)
        commit('resetPasswordError', error)
        throw error
      }
    },
    resetState({ commit }) {
      commit('resetState')
    },
  },
}
