import {
  doFetchOne,
  doFetchMetrics,
  doPostOne,
  doPatchOne,
  doCloneOne,
  doDeleteOne,
  doSendTestSms,
  doBroadcastSmsCountSummary,
} from '@/services/agent/broadcast.service'
import { getNowUtc } from '@/_helpers/date'
import { getField, updateField } from 'vuex-map-fields'

import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { snakeCase } from 'lodash'
const snakes = createHumps(snakeCase)

export const broadcastOne = {
  namespaced: true,
  state: () => ({
    one: {
      criteria: [],
    },
    inProgress: false,
    inProgressCriteria: false, // ? fanList?

    metrics: null,
    inProgressMetrics: false,

    // WIP
    smsCosts: null, // costs
    inProgressSmsCosts: false, // reload
    smsCostsError: '',
  }),
  getters: {
    getField,
    // TODO - make use of it
    // To prevent loop, we don't auto-save emailtemplate to broadcast, but prompt the user to do so?
    // we should compare local emailTemplate object with one.emailTemplate
    // and prompt user to set/confirm email template for broadcast
    // missingEmailTemplate: (state) => {
    //     return state.one && state.one?.channel === 'email' && !state.one?.emailTemplate
    // },
    // missingDescription: (state) => {
    //     return state.one && !state.one.description
    // },
    // isAutomation //...// TODO
    selectedSmsCosts: state => (state.smsCosts || []).filter(f => !f.removed).sort((a, b) => b.fans - a.fans),
    removedSmsCosts: state => (state.smsCosts || []).filter(f => f.removed).sort((a, b) => b.fans - a.fans),
    totalSmsFans: (state, getters) => getters.selectedSmsCosts.reduce((sum, country) => sum + (country?.fans || 0), 0),
    totalSmsCost: (state, getters) => getters.selectedSmsCosts.reduce((sum, country) => sum + (country?.cost || 0), 0),
    requiresAmericanValidation: (state, getters) => !!getters.selectedSmsCosts.find(f => f.countryCallingCode === '1'),
    requiresWorldValidation: (state, getters) => !!getters.selectedSmsCosts.find(f => f.countryCallingCode !== '1'),
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo
    },
    inProgressCriteria(state, yesOrNo = false) {
      state.inProgressCriteria = yesOrNo
    },
    inProgressSmsCosts(state, yesOrNo = false) {
      state.inProgressSmsCosts = yesOrNo
    },
    setOne(state, one = {}) {
      // Object.assign ?
      state.one = {
        ...one,
        criteria: one?.criteria || [], // not normalized, so we know it needs save
        startAt: one?.startAt || getNowUtc(),
        status: one?.status || 'draft',
      }
    },
    setCriteria(state, newCriteria = []) {
      state.one.criteria = newCriteria
    },
    setMetrics(state, one = null) {
      state.metrics = one
    },
    inProgressMetrics(state, yesOrNo = false) {
      state.inProgressMetrics = yesOrNo
    },
    setStartAt(state, startAt = null) {
      state.one.startAt = startAt
    },
    setDeliverTime(state, deliverTime = null) {
      state.one.deliverTime = deliverTime
    },
    setCode(state, code = null) {
      state.one.uniqueCodesTag = code
    },
    setTag(state, tag = null) {
      state.one.tag = tag
    },
    setCatalogId(state, catalogId = null) {
      state.one.catalogueItemId = catalogId
    },
    setSubject(state, subject = '') {
      state.one.subject = subject
    },
    setSmsTemplate(state, smsTemplate = null) {
      state.one.smsTemplate = smsTemplate
    },
    setSmsHelpOnly(state, helpOnly = false) {
      state.one.helpOnly = helpOnly
    },
    setSmsLink(state, smsLink = null) {
      state.one.smsLink = smsLink
    },
    setCatalogueItemId(state, catalogueItemId = null) {
      state.one.catalogueItemId = catalogueItemId
    },
    setDescription(state, description = '') {
      state.one.description = description
    },
    setLocalTimezoneDelivery(state, localTimezoneDelivery = false) {
      state.one.localTimezoneDelivery = localTimezoneDelivery
    },
    setDelay(state, delay = null) {
      state.one.delay = delay
    },
    setOnlyOnce(state, onlyOnce = false) {
      state.one.onlyOnce = onlyOnce
    },
    setEmailMasterTemplateId(state, emailMasterTemplateId = null) {
      state.one.emailMasterTemplateId = emailMasterTemplateId
    },
    setTrigger(state, trigger = '') {
      state.one.trigger = trigger
    },
    setChannel(state, channel = '') {
      state.one.channel = channel
    },
    setType(state, type = 'manual') {
      state.one.type = type
    },
    smsCosts(state, costs = []) {
      state.smsCosts = costs
    },
    smsCostsError(state, error = '') {
      state.smsCostsError = error
    },
    markSmsCostRemoved(state, { country = null, removed = false}) {
      if(!country) return
      const found = (state.smsCosts || []).find((f) => f === country)
      found.removed = removed
    },
  },
  actions: {
    new: ({ commit, dispatch, rootState }, one = {}) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('inProgress', true)
      dispatch('resetOne')
      commit('inProgressMetrics', true)

      dispatch('setOne', {
        type: one.type || 'manual',
        artistId: rootState.artistOne.one.id,
        channel: one.channel || '',
        subject: '',
        description: '',
        deliverTime: null,
        startAt: null,
        localTimezoneDelivery: false,
        status: 'draft',
        criteria: [],
        // onlyOnce: true,
      })
    },
    fetchOne: async ({ commit, dispatch }, { broadcastId = null }) => {
      if (!broadcastId) {
        console.log('TODO - no broadcastId in fetchOne')
        // redirect?
      }
      try {
        commit('inProgress', true)
        dispatch('resetOne')
        commit('inProgressMetrics', true)

        const list = await doFetchOne(broadcastId)
        const one = humps(list[0]) // meybe normalize here instead
        dispatch('setOne', one)
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    setOne: async (
      { state, commit, dispatch, rootState },
      one = { status: 'draft', criteria: [] },
    ) => {
      commit('inProgress', true)
      commit('setOne', one)
      dispatch('fanList/initializeActiveCriteria', one.criteria || [], { root: true })
      dispatch('emailTemplateOne/fetchOne', one.emailTemplateId, { root: true })
      dispatch('emailMasterTemplateOne/fetchOne', one.emailMasterTemplateId, { root: true })
      // if(one?.channel === 'sms') //...// TODO? - later, maybe not needed
      dispatch('fetchMetrics')

      if (one?.channel === 'presave') {
        dispatch('catalogList/fetchList', { artistId: rootState.artistOne.one.id }, { root: true }) // use root artistId in catalogList store
        dispatch('catalogOne/fetchOne', state.one?.catalogueItemId, { root: true })
      }
      // else reset?

      commit('inProgress')
    },
    resetOne: ({ commit, dispatch }) => {
      commit('setOne')
      commit('setMetrics')
      dispatch('resetSmsCosts')
      dispatch('emailTemplateOne/resetOne', null, { root: true })
      dispatch('emailMasterTemplateOne/resetOne', null, { root: true })
    },
    save: async ({ state, commit, dispatch, rootState, rootGetters }, updateFields = {}) => {
      commit('inProgress', true)
      try {
        const broadcast = {
          ...state.one,
          criteria: JSON.parse(
            JSON.stringify(rootGetters['fanList/activeCriteriaWithValues'] || []),
          ), // danger of nulling
          emailTemplateId: rootState.emailTemplateOne.one?.id || null, // danger of nulling
          emailMasterTemplateId: rootState.emailMasterTemplateOne.one?.id || null, // danger of nulling
          ...(updateFields || {}),
          artistId: rootState.artistOne.one.id,
        }

        if (!broadcast.id) {
          const response = await doPostOne(snakes(Object.assign({}, broadcast)))
          if (response) {
            const one = humps(response)
            dispatch('setOne', one)
            commit('inProgress')
            return { new: true, broadcast: response }
          } else {
            throw 'Could not create broadcast'
          }
        } else {
          const response = await doPatchOne(snakes(Object.assign({}, broadcast)))
          if (response) {
            const one = humps(response)
            commit('setOne', one)
            commit('inProgress')
            return { update: true, broadcast: response }
          } else {
            throw 'Could not save broadcast'
          }
        }
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    // TODO - would be better if we have separate, dedicated calls
    // eg
    // saveCataogueItemId
    // saveCriteria
    // saveEmailTemplateIds
    // etc
    delete: async ({ commit, state }) => {
      if (!state.one?.id) return
      commit('inProgress', true)
      try {
        await doDeleteOne(state.one.id)
        commit('setOne', {})
        commit('inProgress')
        return { removed: true }
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },

    saveEmailTemplate: async ({ commit, dispatch }, emailTemplate) => {
      commit('inProgress', true)
      try {
        await dispatch('emailTemplateOne/generateEmailTemplate', emailTemplate, { root: true })
        dispatch('save')
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    activate: async ({ commit, dispatch }) => {
      commit('inProgress', true)
      try {
        await dispatch('save', { status: 'active' })
        commit('inProgress')
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    disable: async ({ commit, dispatch }) => {
      commit('inProgress', true)
      try {
        dispatch('save', { status: 'disabled' })
        commit('inProgress')
      } catch (err) {
        commit('inProgress')
        throw err
      }
    },
    sendTestSms: async ({ rootState, state }, smsTo) => {
      const fan = rootState.fanOne.one // ?
      try {
        const resp = await doSendTestSms({
          broadcastId: state.one.id,
          phoneNumber: smsTo || fan.phoneNumber,
        })
        if (resp.status === 'ERROR') throw new Error(resp.message || 'Could not send test sms')
      } catch (error) {
        throw new Error(error.message || 'Could not send test sms')
      }
    },

    fetchMetrics: async ({ commit, state }) => {
      try {
        commit('setMetrics', null)
        commit('inProgressMetrics', true)
        if (state.one?.id && state.one?.status != 'draft') {
          const metrics = await doFetchMetrics({ broadcast_id: state.one.id })
          commit('setMetrics', humps(metrics))
        }
        commit('inProgressMetrics')
      } catch (err) {
        commit('inProgressMetrics')
        throw err
      }
    },
    // why duplicate? w/wo broadcastId - any difference?
    fetchBroadcastMetrics: async ({ commit }, { broadcastId }) => {
      try {
        commit('setMetrics', null)
        commit('inProgressMetrics', true)
        if (broadcastId) {
          const metrics = await doFetchMetrics({ broadcast_id: broadcastId })
          commit('setMetrics', humps(metrics))
        }
        commit('inProgressMetrics')
      } catch (err) {
        commit('inProgressMetrics')
        throw err
      }
    },
    resetMetrics: ({ commit }) => {
      commit('setMetrics', null)
    },

    // TODO - use id only
    cloneSpecified: async ({ commit }, broadcast) => {
      commit('inProgress', true)
      let response = await doCloneOne(broadcast.id)
      commit('inProgress')
      return response
    },

    fetchSmsCosts: async ({ commit, dispatch, rootState, rootGetters }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      dispatch('resetSmsCosts')
      commit('inProgressSmsCosts', true)
      try {
        if(!rootState.artistOne?.callingCountries) {
          await dispatch('artistOne/fetchCallingCountries', null, { root: true })
          if(rootState.artistOne?.callingCountriesError) throw new Error('oops')
        }

        const criteria = JSON.parse(JSON.stringify(rootGetters['fanList/activeCriteriaWithValues']))
        const cccCriteria = criteria.find(f => f.criteriaType === 'Country Calling Codes')
        if(cccCriteria) cccCriteria.value = []
        const costs = await doBroadcastSmsCountSummary(
          rootState.artistOne.one.id,
          snakes(criteria || [])
        )

        const { callingCountries = null } = rootState.artistOne
        const cccCrit = rootGetters['fanList/activeCriteriaWithValues']?.find(f => f.criteriaType === 'Country Calling Codes')
        const costsFormatted = (Object.values(callingCountries) || [])?.map(m => {
          const costItem = costs.find(f => f.countryCallingCode === m.callingCountryCode)
          return {
            countryCallingCode: m.callingCountryCode,
            fans: costItem?.fans || 0,
            sms: costItem?.sms || 0,
            countryName: m.country || null,
            cost: (m.price || 0) * (costItem?.sms || 0),
            removed: !cccCrit?.value?.find(f => f === m.callingCountryCode),
          }
        })
        commit('smsCosts', costsFormatted)
      } catch (err) {
        console.log(err)
        commit('smsCosts', [])
        commit('smsCostsError', 'Could not retrieve cost list. Please refresh and try again.')
      }
      commit('inProgressSmsCosts')
    },
    markSmsCostRemoved: async ({ commit }, { country = null, removed = false }) => {
      commit('inProgressSmsCosts', true)
      await new Promise((r) => setTimeout(r, 200))
      commit('markSmsCostRemoved', { country, removed })
      commit('inProgressSmsCosts')
    },
    resetSmsCosts: ({ commit }) => {
      commit('smsCosts', [])
      commit('smsCostsError')
    },
    applySmsCostsToCriteria: ({ getters, dispatch }) => {
      dispatch('fanList/setActiveCriteriaItem',
        {
          criteriaType: 'Country Calling Codes',
          value: getters.selectedSmsCosts.map(m => m.countryCallingCode),
        },
        { root: true }
      )
    },
  },
}
