<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 32">
    <path d="M9.89999 2.8C9.89999 4.34 8.65 5.59 7.1 5.59C5.55 5.59 4.31 4.34 4.31 2.8C4.31 1.26 5.55 0 7.1 0C8.65 0 9.89999 1.25 9.89999 2.8Z"/>
    <path d="M11 6.09H11.61C11.31 6.07 11.08 6.09 11 6.09Z"/>
    <path d="M13.74 7.12001C13.41 6.56001 12.82 6.30001 12.26 6.18001C12.04 6.13001 11.81 6.10001 11.62 6.10001H11.15H10.44H2.99002C2.75002 6.10001 2.37002 6.11001 1.96002 6.20001C1.14002 6.37001 0.220022 6.85001 0.210022 8.10001V16.56C0.210022 17.26 0.780019 17.83 1.48002 17.83C2.18002 17.83 2.75002 17.26 2.75002 16.56V8.64001H3.07001V19.01C3.07001 19.06 3.07001 19.11 3.07001 19.15V30.29C3.07001 31.16 3.78002 31.86 4.65002 31.86C5.52002 31.86 6.22002 31.15 6.22002 30.29V19.15C6.22002 19.15 6.22002 19.06 6.22002 19.01C6.22002 18.52 6.61002 18.13 7.10002 18.13C7.59002 18.13 7.99002 18.52 7.99002 19.01C7.99002 19.06 7.99002 19.11 7.99002 19.15V30.29C7.99002 31.16 8.70002 31.86 9.56002 31.86C10.42 31.86 11.14 31.15 11.14 30.29V19.15C11.14 19.15 11.14 19.06 11.14 19.01V8.64001H11.45V16.56C11.45 17.26 12.02 17.83 12.72 17.83C13.42 17.83 14 17.26 14 16.56V8.13001C14 7.72001 13.9 7.40001 13.75 7.14001L13.74 7.12001Z"/>
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
