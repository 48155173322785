import { mapSummary, doFetchTopCountries } from '@/services/agent/geographical.service'
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'

export const geographicalOne = {
  namespaced: true,
  state: () => ({
    topCountries: [],
    topCities: [],
    target: {
      fans: 10,
      distance: 65,
    },
    inProgress: false,
    citiesInProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setTopCountries(state, topCountries) {
      state.topCountries = topCountries
    },
    setTopCities(state, topCities) {
      state.topCities = topCities
    },
    setDistance(state, distance) {
      state.target.distance = distance
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    citiesInProgress(state, yesOrNo) {
      state.citiesInProgress = yesOrNo
    },
  },
  actions: {
    fetchTopCountries: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      try {
        commit('inProgress', true)
        const top = await doFetchTopCountries(rootState.artistOne.one.id)
        if (Array.isArray(top)) commit('setTopCountries', humps(top))
        else commit('setTopCountries', [])
        commit('inProgress', false)
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    fetchTopCities: async ({ commit, rootState }, { countries = null } = {}) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      try {
        commit('citiesInProgress', true)
        const citiesList = await mapSummary(rootState.artistOne.one.id, countries)
        if (citiesList) commit('setTopCities', citiesList)
        else commit('setTopCities', [])
        commit('citiesInProgress', false)
      } catch (err) {
        commit('citiesInProgress', false)
        throw err
      }
    },
    setDistance: async ({ commit }, distance) => {
      commit('setDistance', distance)
    },
    resetOne: async ({ commit }) => {
      commit('setTopCities', [])
      commit('setTopCountries', [])
    },
  },
}
