import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth'
import { wrapRequest } from '../nav'

const spotifyClientId = '122c11a321f04639b208230f0df883e6' // Mate K. test
const spotifyClientSecret = '6dd8540d92e24dd3b90a9b46c8449fbe' // Mate K. test
// https://developer.spotify.com/documentation/web-api/tutorials/getting-started#request-an-access-token
// TODO: move this to our backend
const getSpotifyToken = async () => {
  const lcSpotifyToken = localStorage.getItem('spotifyAccessToken')
  if (lcSpotifyToken) {
    const tokenJson = JSON.parse(lcSpotifyToken)
    if (tokenJson?.expires_at) {
      const now = Math.floor(new Date().getTime() / 1000)
      if (now < tokenJson.expires_at) return tokenJson.token
      localStorage.removeItem('spotifyAccessToken')
    }
  }

  const response = await fetch('https://accounts.spotify.com/api/token', {
    body: `grant_type=client_credentials&client_id=${spotifyClientId}&client_secret=${spotifyClientSecret}`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  })
  const tokenObject = await response.json()

  if (tokenObject) {
    localStorage.setItem(
      'spotifyAccessToken',
      JSON.stringify({
        token: tokenObject.access_token,
        expires_at: Math.floor(new Date().getTime() / 1000) + tokenObject.expires_in, // + 1 hour or 3600 sec
      }),
    )
  }
  return tokenObject.access_token || null
}

export const spotifySearch = async (search) => {
  if (!search) return null
  const token = await getSpotifyToken()
  // max limit is 50
  const searchParams = search.q
    ? `search?q=${search.q}&limit=50&market=NZ`
    : search.albumId
      ? `albums/${search.albumId}`
      : `tracks/${search.trackId}`
  const url = `https://api.spotify.com/v1/${searchParams}`
  const resp = await fetch(url, { headers: { Authorization: `Bearer  ${token}` } })
  const json = await resp.json()
  return json
}

const deezerClientId = '562922' // Adam test
const deezerClientSecret = 'c50d9f58ed31ac6f8f02561fba89d527' // Adam test

export const deezerSearch = async (search) => {
  if (!search) return null
  //localhost CORS issue
  const searchParams = search.isrc
    ? `track/isrc:${search.isrc}`
    : search.albumId
      ? `album/${search.albumId}`
      : search.trackId
        ? `track/${search.trackId}`
        : `search/album?q=artist:"${search.artistName}" album:"${search.q}"`
  const url = `https://corsproxy.io/?https://api.deezer.com/${searchParams}&appid=${deezerClientId}&secret=${deezerClientSecret}`
  const resp = await fetch(url)
  const json = await resp.json()
  return json
}

const appleDeveloperToken = wrapRequest(() => {
  return fetch(`${openstageApi2Url}/appleJwt`, { method: 'GET' })
})

export const appleSearch = async (search) => {
  if (!search) return null
  const token = await appleDeveloperToken()
  const storefront = 'nz'
  const searchParams = search.isrc
    ? `songs?filter[isrc]=${search.isrc}`
    : search.upc
      ? `albums?filter[upc]=${search.upc}`
      : search.albumId
        ? `albums/${search.albumId}`
        : search.trackId
          ? `songs/${search.trackId}`
          : `search?types=albums&term=${search.title}`
  const url = `https://api.music.apple.com/v1/catalog/${storefront}/${searchParams}`
  const resp = await fetch(url, { headers: { Authorization: `Bearer  ${token.jwt}` } })
  const json = await resp.json()
  return json
}

export const doFetchList = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  }

  return fetch(`${openstageApiCrudUrl}/catalogue_item?artist_id=eq.${artistId}`, requestOptions)
})

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/catalogue_item?id=eq.${id}`, requestOptions)
})

export const doDeleteOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/catalogue_item?id=eq.${id}`, requestOptions)
})

export const doPostOne = wrapRequest((page) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(page),
  }
  return fetch(`${openstageApiCrudUrl}/catalogue_item`, requestOptions)
})

export const doPostAlbumTracks = wrapRequest((body) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(body),
  }
  return fetch(`${openstageApi2Url}/catalogueItems`, requestOptions)
})

export const doPatchOne = wrapRequest((page) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(page),
  }
  return fetch(`${openstageApiCrudUrl}/catalogue_item?id=eq.${page.id}`, requestOptions)
})

export const musicSearch = wrapRequest((query) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: '',
  }
  return fetch(`${openstageApi2Url}/musicSearch?${query}`, requestOptions)
})
