import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth'
import { wrapRequest, wrapRequestNoError } from '../nav'

export const doFetchOne = wrapRequest((broadcastId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/broadcast?id=eq.${broadcastId}`, requestOptions)
})

export const doFetchLinks = wrapRequest((artistId, broadcastId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      broadcastId,
    }),
  }
  return fetch(`${openstageApi2Url}/linkSummary`, requestOptions)
})

export const doFetchLinksByPage = wrapRequest((artistId, pageId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      pageId,
    }),
  }
  return fetch(`${openstageApi2Url}/linkSummary`, requestOptions)
})

export const doFetchActivityFromClicks = wrapRequest((artistId, broadcastId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId: artistId,
      broadcastId: broadcastId,
    }),
  }
  return fetch(`${openstageApi2Url}/activityFromClicks`, requestOptions)
})

export const doFetchListByArtist = wrapRequest(
  (artistId, type = null, limit = 100, search = null, channel = null) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        ...authHeader(),
      },
    }
    let queryParams = new URLSearchParams()
    queryParams.append('artist_id', `eq.${artistId}`)
    queryParams.append('limit', limit || 100)
    if (type) queryParams.append('type', `eq.${type}`)
    if (search) queryParams.append('description', `ilike.*${search}*`)
    if (channel) queryParams.append('channel', `eq.${channel}`)
    queryParams = queryParams.toString()
    if (queryParams) queryParams = `?${queryParams}`

    let url = `${openstageApiCrudUrl}/broadcast${queryParams}`
    return fetch(url, requestOptions)
  },
)

export const doFetchSpotifyBroadcasts = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  let url = `${openstageApi2Url}/spotifyBroadcasts?artistId=${artistId}`
  return fetch(url, requestOptions)
})

export const doFetchListByFan = wrapRequest((artistId, fanId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  let url = `${openstageApiCrudUrl}/broadcast_message?artist_id=eq.${artistId}&fan_id=eq.${fanId}&read_at=eq.true`
  return fetch(url, requestOptions)
})

export const doPostOne = wrapRequest((message) => {
  delete message.email_template
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(message),
  }
  return fetch(`${openstageApiCrudUrl}/broadcast`, requestOptions)
})

export const doPatchOne = wrapRequest((message) => {
  delete message.email_template
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(message),
  }
  return fetch(`${openstageApiCrudUrl}/broadcast?id=eq.${message.id}`, requestOptions)
})

export const doDeleteOne = (broadcastId) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/broadcast?id=eq.${broadcastId}`, requestOptions)
}

export const doCloneOne = wrapRequest((broadcastId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify({ broadcastId }),
  }
  return fetch(`${openstageApi2Url}/cloneBroadcast`, requestOptions)
})

export const doSendTestEmail = wrapRequest((broadcastId, to) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ broadcastId: broadcastId, email: to }),
  }
  return fetch(`${openstageApi2Url}/sendTestEmail`, requestOptions)
})

export const doSendTestSms = wrapRequestNoError(({ broadcastId, phoneNumber }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ broadcastId, phoneNumber }),
  }
  return fetch(`${openstageApi2Url}/sendTestSms`, requestOptions)
})

export const doFetchMetrics = wrapRequest((broadcast) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ broadcastId: broadcast.broadcast_id }),
  }
  return fetch(`${openstageApi2Url}/broadcastMetrics`, requestOptions)
})

export const retargetBroadcast = wrapRequest((broadcastId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ broadcastId }),
  }
  return fetch(`${openstageApi2Url}/retargetBroadcast`, requestOptions)
})

export const listUniqueCodes = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/listUniqueCodes`, requestOptions)
})

export const doBroadcastSmsCountSummary = wrapRequest((artistId, criteria) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, criteria }),
  }
  return fetch(`${openstageApi2Url}/broadcastSmsCountSummary`, requestOptions)
})
