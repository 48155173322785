import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth'
import { wrapRequest } from '../nav'

export const doFetchOne = wrapRequest((emailTemplateId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/email_template?id=eq.${emailTemplateId}`, requestOptions)
})

export const doPostOne = wrapRequest((email_template) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(email_template),
  }

  return fetch(`${openstageApiCrudUrl}/email_template`, requestOptions)
})

export const doPatchOne = wrapRequest((email_template) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(email_template),
  }
  return fetch(`${openstageApiCrudUrl}/email_template?id=eq.${email_template.id}`, requestOptions)
})

export const checkLinks = wrapRequest((emailTemplateId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      emailTemplateId,
    }),
  }
  return fetch(`${openstageApi2Url}/checkLinks`, requestOptions)
})
