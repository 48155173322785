import './assets/main.css'

import { createApp } from 'vue'
// import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router/router'
import store from '@/stores/agent'
import VueGtag from 'vue-gtag'
import VueCryptojs from 'vue-cryptojs'
import * as Sentry from '@sentry/vue'

//Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//Vuetify icons
import { mdi } from 'vuetify/iconsets/mdi'
import discord from '@/assets/icons/icon-discord.vue'
import x from '@/assets/icons/icon-x.vue'
import stripe from '@/assets/icons/icon-stripe.vue'
import deezer from '@/assets/icons/icon-deezer.vue'
import bandsintown from '@/assets/icons/icon-bandsintown.vue'
import seated from '@/assets/icons/icon-seated.vue'
import songkick from '@/assets/icons/icon-songkick.vue'
import tiktok from '@/assets/icons/icon-tiktok.vue'
import permissionOpenstage from '@/assets/icons/icon-permission-openstage.vue'
import permissionMarketing from '@/assets/icons/icon-permission-marketing.vue'
import permissionLegitimate from '@/assets/icons/icon-permission-legitimate.vue'
import fanverse from '@/assets/icons/icon-fanverse.vue'
import smsInbound from '@/assets/icons/icon-sms-in.vue'
import smsOutbound from '@/assets/icons/icon-sms-out.vue'
import openstage from '@/assets/icons/icon-openstage.vue'

import iconFan from '@/assets/icons/NewDashboard/IconFan.vue'
import iconFans from '@/assets/icons/NewDashboard/IconFans.vue'
import iconArrowBlockRight from '@/assets/icons/NewDashboard/IconArrowBlockRight.vue'
import iconRoundYoutube from '@/assets/icons/NewDashboard/IconRoundYoutube.vue'
import iconRoundTiktok from '@/assets/icons/NewDashboard/IconRoundTiktok.vue'
import iconRoundX from '@/assets/icons/NewDashboard/IconRoundX.vue'
import iconRoundFacebook from '@/assets/icons/NewDashboard/IconRoundFacebook.vue'
import iconRoundInstagram from '@/assets/icons/NewDashboard/IconRoundInstagram.vue'

// import Toast from "vue-toastification"
import ToastificationPlugin from '@/plugins/toastification'

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'dark',
  },
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases: {
      openstage,
      discord,
      x,
      stripe,
      deezer,
      bandsintown,
      seated,
      songkick,
      tiktok,
      permissionOpenstage,
      permissionMarketing,
      permissionLegitimate,
      fanverse,
      smsInbound,
      smsOutbound,

      // new dashboard icons
      iconFan,
      iconFans,
      iconArrowBlockRight,
      iconRoundYoutube,
      iconRoundTiktok,
      iconRoundX,
      iconRoundFacebook,
      iconRoundInstagram,
    },
    sets: {
      mdi,
    },
  },
})

const app = createApp(App)
// app.use(createPinia())
app.use(ToastificationPlugin)
app.use(VueGtag, {
  config: { id: 'G-GQTDNN0C9Y' },
})
app.use(router)
app.use(VueCryptojs)
app.use(vuetify)
app.use(store)

Sentry.init({
  app,
  dsn: 'https://93121268ed4d335d6aa5556f7fc6af86@o613414.ingest.sentry.io/4506662596968448',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// vue router 4: now all navigations are async, need to make sure router is ready
router.isReady().then(() => {
  app.mount('#app')
})
