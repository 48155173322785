import {
  moveOrganization,
  doFetchOne,
  doFetchOneByShortName,
  doPostOne,
  doPatchOne,
  doFetchArtistMetrics,
  doFetchDemographics,
  doDeleteOne,
  doCallingCountryPrice,
} from '@/services/agent/artist.service'
import { doFetchSmsCredit } from '@/services/agent/organization.service' // ADAM - Ask if this is a ORG or ARTIST service, and then move appropriately
import { getField, updateField } from 'vuex-map-fields'
import humps from 'lodash-humps'
import createHumps from 'lodash-humps/lib/createHumps'
import { snakeCase } from 'lodash'
const snakes = createHumps(snakeCase)
import { artistFanHistory, artistEmailHistory } from '../../services/agent/artist.service'
import moment from 'moment'

const getDateArray = function (start, end) {
  var arr = new Array(),
    dt = new Date(start)
  while (dt <= end) {
    arr.push(new Date(dt))
    dt.setDate(dt.getDate() + 1)
  }
  return arr
}

export const artistOne = {
  namespaced: true,
  state: () => ({
    one: {},
    fanHistoryMetrics: null,
    emailHistoryMetrics: null,
    artistMetrics: null,
    smsDetails: null,
    inProgress: true,
    artistMetricsInProgress: false,
    demographics: null,
    statsDateRange: -1,
    dashboardMetricsHasFans: false,
    dashboardMetricsHasIncome: false,
    dashboardMetricsHasEngagement: false,

    callingCountries: null,
    callingCountriesInProgress: false,
    callingCountriesError: '',
  }),
  getters: {
    getField,
    homepageId: (state) => {
      return state?.one?.page_id || null
    },
    currencyFromPriceList: (state) => {
      const entries = Object.entries(state.callingCountries || {})
      if(!entries?.length) return null
      return entries[0].currency || null
    },
  },
  mutations: {
    updateField,
    setOne(state, one) {
      if (!one.homepageParameters) {
        one.homepageParameters = {}
      }
      state.one = one
      //Use initial and updated objects instead of the values below - tidyup
      state.statsDateRange = localStorage.getItem('dateRange') || -1
      state.dashboardMetricsHasFans = false
      state.dashboardMetricsHasIncome = false
      state.dashboardMetricsHasEngagement = false
    },
    setDateRange(state, range) {
      state.statsDateRange = range
    },
    setDashboardMetric(state, data) {
      if (data.key === 'fans') {
        state.dashboardMetricsHasFans = data.value
      }
      if (data.key === 'income') {
        state.dashboardMetricsHasIncome = data.value
      }
      if (data.key === 'engagement') {
        state.dashboardMetricsHasEngagement = data.value
      }
    },
    setFanHistoryMetrics(state, metrics) {
      state.fanHistoryMetrics = metrics
    },
    setEmailHistoryMetrics(state, metrics) {
      state.emailHistoryMetrics = metrics
    },
    artistMetrics(state, artistMetrics) {
      state.artistMetrics = artistMetrics
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    artistMetricsInProgress(state, yesOrNo) {
      state.artistMetricsInProgress = yesOrNo
    },
    demographics(state, demographics) {
      state.demographics = demographics
    },
    smsDetails(state, value) {
      state.smsDetails = value
    },
    callingCountries(state, callingCountries = null) {
      state.callingCountries = callingCountries
    },
    callingCountriesInProgress(state, yesOrNo = false) {
      state.callingCountriesInProgress = yesOrNo
    },
    callingCountriesError(state, error = '') {
      state.callingCountriesError = error
    },
  },
  actions: {
    new({ commit }) {
      commit('inProgress', false)
      commit('setOne', {
        name: '',
        shortName: '',
        avatarUrl: '',
        facebookUrl: '',
        twitterUrl: '',
        spotifyUrl: '',
        appleUrl: '',
        deezerUrl: '',
        instagramUrl: '',
        tiktokUrl: '',
        youtubeUrl: '',
        twitchUrl: '',
        discordUrl: '',
        websiteUrl: '',
        // TODO - add other field defaults?
        // smsPhoneNumber: null,
        // twilioUkVerificationStatus: null,
        // smsPhoneNumberUs: null,
        // twilioUsVerificationStatus: null,
        // voicemailMessage: null,
        // twilioCallAppSid: null,
        // smsHelpText: null,
        // smsBudget: null, // used?
        // smsBudgetStartAt: null, // used?
      })
    },
    fetchOne: async ({ commit, dispatch, rootState }, { artistId, artistShortName, recursive }) => {
      try {
        commit('inProgress', true)
        const list = artistShortName
          ? await doFetchOneByShortName(artistShortName)
          : await doFetchOne(artistId)
        if (!list) throw new Error('Could not get artist details')
        const one = humps(list[0])

        // Map old property to new property
        if (!one.appleUrl && one.homepageParameters && one.homepageParameters.appleMusicUrl) {
          one.appleUrl = one.homepageParameters.appleMusicUrl
        }

        commit('setOne', one)
        commit('inProgress', false)
        if (recursive && one.organizationId != rootState.organizationOne.one.id) {
          dispatch('organizationOne/fetchOne', one.organizationId, { root: true })
        }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    fetchArtistMetrics: async ({ commit }, artistId) => {
      try {
        commit('artistMetricsInProgress', true)
        const data = await doFetchArtistMetrics({ artist_id: artistId })
        if (data?.fans_with_address) delete data.fans_with_address
        const metrics = humps(data)
        commit('artistMetricsInProgress', false)
        commit('artistMetrics', metrics)
      } catch (err) {
        commit('artistMetricsInProgress', false)
        throw err
      }
    },

    fetchFanHistoryMetrics: async ({ commit }, { artistId }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await artistFanHistory(artistId)
        const resultWithTimestamp = result
          .map((m) => {
            const dateString = m.day.split(', ')
            return {
              ...m,
              day: `${dateString[0]} ${dateString[1]}`,
              timestamp: new Date(m.day).getTime(),
            }
          })
          .sort((a, b) => a.timestamp - b.timestamp)
        // Generate all dates between first data record and today
        let endDate = new Date()
        let startDate = new Date()
        const oneDay = 1000 * 3600 * 24
        if (resultWithTimestamp[0]?.day) {
          startDate = new Date(new Date(resultWithTimestamp[0].day) - oneDay)
        }
        const dateArray = getDateArray(Date.parse(startDate), Date.parse(endDate))

        let totalFanHistoryCount = 0
        const resultWithAllDates = dateArray.map((m) => {
          const day = moment(m).format('MMM D YYYY')
          const dayData = resultWithTimestamp.find((f) => f.day === day)
          if (dayData) {
            totalFanHistoryCount += dayData.count
          }
          return {
            day,
            count: dayData?.count || 0,
            total: totalFanHistoryCount,
          }
        })

        const storeObject = {
          daily: resultWithAllDates,
        }
        commit('setFanHistoryMetrics', storeObject)
      } catch (err) {
        throw err
      }
    },

    fetchEmailHistoryMetrics: async ({ commit }, { artistId }) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await artistEmailHistory(artistId)

        const resultWithTimestamp = result
          .map((m) => {
            const dateString = m.day.split(', ')
            return {
              ...m,
              day: `${dateString[0]} ${dateString[1]}`,
              timestamp: new Date(m.day).getTime(),
            }
          })
          .sort((a, b) => a.timestamp - b.timestamp)

        // Generate all dates between first data record and today
        let endDate = new Date()
        let startDate = new Date()
        const oneDay = 1000 * 3600 * 24
        if (resultWithTimestamp[0]?.day) {
          startDate = new Date(new Date(resultWithTimestamp[0].day) - oneDay)
        }
        const dateArray = getDateArray(Date.parse(startDate), Date.parse(endDate))

        let totalSentCount = 0
        let totalReadCount = 0
        const resultWithAllDates = dateArray.map((m) => {
          const day = moment(m).format('MMM D YYYY')
          const dayData = resultWithTimestamp.find((f) => f.day === day)
          if (dayData) {
            totalSentCount += dayData.sent
            totalReadCount += dayData.read
          }
          return {
            day,
            bounced: dayData?.bounced || 0,
            complained: dayData?.complained || 0,
            read: dayData?.read || 0,
            sent: dayData?.sent || 0,
            unsubscribed: dayData?.unsubscribed || 0,
          }
        })

        const storeObject = {
          daily: resultWithAllDates,
          sentCount: totalSentCount,
          readCount: totalReadCount,
        }
        commit('setEmailHistoryMetrics', storeObject)
      } catch (err) {
        throw err
      }
    },

    fetchDemographics: async ({ commit }, artistId) => {
      const data = await doFetchDemographics({ artistId })
      const metrics = humps(data)
      commit('demographics', metrics)
    },

    fetchSMSDetails: async ({ commit }, artistId) => {
      commit('smsDetails', null)
      const apiData = await doFetchSmsCredit(artistId)
      const apiDataCamelCase = humps(apiData)
      commit('smsDetails', apiDataCamelCase)
    },

    save: async ({ commit, state, rootState }, newData) => {
      commit('inProgress', true)
      if(!state.one?.organizationId && !newData.organizationId) newData.organizationId = rootState.organizationOne?.one?.id || null
      try {
        if (undefined === state.one.id || newData.new) {
          delete newData.new
          const artist = await doPostOne(snakes(Object.assign({}, newData)))
          if (artist) {
            commit('setOne', humps(artist))
            commit('inProgress', false)
            return { new: true, id: artist.id }
          }
          else {
            throw 'Could not save artist'
          }
        } else {
          const patch = { ...snakes(state.one), ...snakes(Object.assign({}, newData)) }
          const artist = await doPatchOne(patch)
          if (artist) {
            commit('setOne', humps(artist))
            commit('inProgress', false)
            return { update: true }
          }
          else {
            throw 'Could not save artist'
          }
        }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    moveArtist: async ({ commit }, data) => {
      const { artist, organizationId } = data
      commit('inProgress', true)
      try {
        await moveOrganization(artist.id, organizationId)
        commit('inProgress', false)
        return { update: true }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    delete: async ({ commit, state }) => {
      commit('inProgress', true)
      try {
        await doDeleteOne(state.one.id)
        commit('setOne', {})
        commit('inProgress', false)
        return { removed: true }
      } catch (err) {
        commit('inProgress', false)
        throw err
      }
    },
    setOne: async ({ commit }, page) => {
      commit('setOne', humps(page))
    },
    setDateRange: async ({ commit }, range) => {
      commit('setDateRange', range)
    },
    setDashboardMetric: async ({ commit }, data) => {
      commit('setDashboardMetric', data)
    },
    resetOne: async ({ commit }) => {
      commit('setOne', {})
      commit('callingCountries')
      commit('artistMetrics', null)
    },
    resetMetrics: async ({ commit }) => {
      commit('artistMetrics', null)
    },
    fetchCallingCountries: async ({ commit, state }) => {
      commit('callingCountriesInProgress', true)
      commit('callingCountriesError')
      try {
        let list = await doCallingCountryPrice(state.one.id)
        const callingCountries = {}
        for (let country of list) {
          callingCountries[country.callingCountryCode] = country
        }
        commit('callingCountries', callingCountries)
      } catch (err) {
        console.log(err)
        commit('callingCountriesError', 'Could not retrieve cost list. Please refresh and try again.')
      }
      commit('callingCountriesInProgress')
    },
  },
}
