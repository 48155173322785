import { getField, updateField } from 'vuex-map-fields'

export const storiesFilter = {
  namespaced: true,
  state: () => ({
    search: null,
    audienceId: null,
    fanId: null,
    tags: [],
    pageId: null,
    dateFrom: null,
    dateTo: null,
    type: null,
  }),
  getters: {
    getField,
    currentFilterCount: (state) => {
      return (
        (!state.fanId && state.audienceId ? 1 : 0) +
        ((!state.fanId && state.tags?.length) || 0) +
        (state.pageId ? 1 : 0) +
        (state.dateFrom ? 1 : 0) +
        (state.dateTo ? 1 : 0) +
        (state.type ? 1 : 0)
      )
    },
    currentFilters: (state) => {
      const payload = {}
      if (state.search) payload.search = state.search
      if (state.fanId) payload.fanId = state.fanId
      else {
        if (state.audienceId) payload.audienceId = state.audienceId
        if (state.tags?.length) payload.tags = state.tags
      }
      if (state.pageId) payload.pageId = state.pageId
      if (state.dateFrom) payload.startDate = state.dateFrom
      if (state.dateTo) payload.endDate = state.dateTo
      if (state.type) payload.type = state.type
      return payload
    },
  },
  mutations: {
    updateField,
    search(state, val = '') {
      state.search = val
    },
    audienceId(state, val = null) {
      state.audienceId = val
    },
    fanId(state, val = null) {
      state.fanId = val
    },
    tags(state, val = []) {
      state.tags = val
    },
    pageId(state, val = null) {
      state.pageId = val
    },
    dateFrom(state, val = null) {
      state.dateFrom = val
    },
    dateTo(state, val = null) {
      state.dateTo = val
    },
    type(state, val = null) {
      state.type = val
    },
  },
  actions: {
    setFanId: ({ commit }, fanId = null) => {
      commit('fanId', fanId)
      commit('audienceId')
      commit('tags')
      // NOTE: keeping dates and type
    },
    reset: ({ commit }) => {
      commit('search')
      commit('audienceId')
      commit('tags')
      commit('pageId')
      commit('dateFrom')
      commit('dateTo')
      commit('type')
    },
  },
}
