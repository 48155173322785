import { authHeader } from '../auth'
import { wrapRequest } from '../nav'

export const doFetchMarkerData = wrapRequest((artistId, target, countries) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      distance: target.distance,
      fans: target.fans,
      countries,
    }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/magnetizedCities`, requestOptions)
})

export const doFetchTopCountries = wrapRequest((artistId, pageIds = null, target = null) => {
  const payload = { artistId: artistId }
  if (pageIds?.length) payload.page_ids = pageIds
  if (target) payload.target = target
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify(payload),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/countriesWithFans`, requestOptions)
})

export const doFetchAllCountries = wrapRequest((countryCode = null) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      countryCode: countryCode || null,
    }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/allCountries`, requestOptions)
})

export const doFetchCities = wrapRequest((countryCode = null, id = null) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      countryCode: countryCode || null,
      id: id || null,
    }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/cities`, requestOptions)
})

/* NEW */

export const mapSummary = wrapRequest((artistId = null, countries = null) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      countries,
    }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/mapSummary`, requestOptions)
})
