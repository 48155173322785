import { wrapRequest } from './nav'

const doLogin = wrapRequest((username, password) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: username,
      password,
    }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/login`, requestOptions)
})

const doLoginRefresh = wrapRequest((auth, artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + auth,
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${import.meta.env.VITE_OPENSTAGE_API2_URL}/refreshLogin`, requestOptions)
})

// const doLogout = () => {
//     // remove user from local storage to log user out
//     localStorage.removeItem('user_token')
// }

export default {
  doLogin,
  doLoginRefresh,
  // doLogout,
}
