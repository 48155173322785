import { doFetchListByArtist } from '@/services/agent/broadcast.service'

import { getField, updateField } from 'vuex-map-fields'

import humps from 'lodash-humps'

export const broadcastList = {
  namespaced: true,
  state: () => ({
    list: [],
    inProgress: false,
    error: null,

    filteredList: [],
    filterSearch: '',
    filterChannel: null,
  }),
  getters: {
    getField,
    isFiltered: (state) => {
      return !!state.filterSearch || !!state.filterChannel
    },
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.list = list
    },
    setFilteredList(state, list) {
      state.filteredList = list
    },
    addMessage(state, message) {
      state.list.push(message)
    },
    editMessage(state, { index, message }) {
      state.list[index] = message
    },
    setFilterSearch(state, search = '') {
      state.filterSearch = search || ''
    },
    setFilterChannel(state, channel = null) {
      state.filterChannel = channel || null
    },
  },
  actions: {
    fetchListByArtist: async ({ commit }, artistId) => {
      commit('inProgress', true)
      commit('setFilterSearch')
      commit('setFilterChannel')
      const list = await doFetchListByArtist(artistId)
      commit('inProgress', false)
      commit('setList', list ? humps(list) : [])
    },
    lookupList: async (
      { commit, rootState },
      { type = null, limit = null, search = null, channel = null },
    ) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id')
        return
      }
      commit('setFilterSearch', search)
      commit('setFilterChannel', channel)
      commit('inProgress', true)
      const list = await doFetchListByArtist(
        rootState.artistOne.one.id,
        type,
        limit,
        search,
        channel,
      )
      commit('inProgress', false)
      commit('setFilteredList', list ? humps(list) : [])
    },
    resetList: async ({ commit }) => {
      commit('setList', [])
      commit('setFilteredList', [])
    },
  },
}
