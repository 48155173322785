<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18">
  <g clip-path="url(#clip0_835_46847)">
    <path d="M11.2227 9C11.2227 9.95142 10.4514 10.7227 9.5 10.7227C8.54858 10.7227 7.77734 9.95142 7.77734 9C7.77734 8.04858 8.54858 7.27734 9.5 7.27734C10.4514 7.27734 11.2227 8.04858 11.2227 9Z"/>
    <path d="M13.5292 5.95253C13.4464 5.72813 13.3143 5.52502 13.1427 5.35831C12.9759 5.18665 12.773 5.05453 12.5484 4.97173C12.3663 4.901 12.0928 4.81682 11.5889 4.79388C11.0439 4.76903 10.8804 4.76367 9.50055 4.76367C8.12053 4.76367 7.95711 4.76889 7.41219 4.79375C6.90833 4.81682 6.63463 4.901 6.45267 4.97173C6.22813 5.05453 6.02502 5.18665 5.85844 5.35831C5.68678 5.52502 5.55467 5.728 5.47173 5.95253C5.401 6.13463 5.31682 6.40833 5.29388 6.91219C5.26903 7.45711 5.26367 7.62053 5.26367 9.00055C5.26367 10.3804 5.26903 10.5439 5.29388 11.0889C5.31682 11.5928 5.401 11.8663 5.47173 12.0484C5.55467 12.273 5.68665 12.4759 5.85831 12.6427C6.02502 12.8143 6.228 12.9464 6.45253 13.0292C6.63463 13.1001 6.90833 13.1843 7.41219 13.2072C7.95711 13.2321 8.12039 13.2373 9.50041 13.2373C10.8806 13.2373 11.044 13.2321 11.5888 13.2072C12.0926 13.1843 12.3663 13.1001 12.5484 13.0292C12.9991 12.8554 13.3554 12.4991 13.5292 12.0484C13.6 11.8663 13.6841 11.5928 13.7072 11.0889C13.7321 10.5439 13.7373 10.3804 13.7373 9.00055C13.7373 7.62053 13.7321 7.45711 13.7072 6.91219C13.6843 6.40833 13.6001 6.13463 13.5292 5.95253ZM9.50055 11.6542C8.03484 11.6542 6.84666 10.4661 6.84666 9.00041C6.84666 7.5347 8.03484 6.34666 9.50055 6.34666C10.9661 6.34666 12.1543 7.5347 12.1543 9.00041C12.1543 10.4661 10.9661 11.6542 9.50055 11.6542ZM12.2592 6.86192C11.9167 6.86192 11.639 6.58424 11.639 6.24174C11.639 5.89925 11.9167 5.62157 12.2592 5.62157C12.6017 5.62157 12.8794 5.89925 12.8794 6.24174C12.8793 6.58424 12.6017 6.86192 12.2592 6.86192Z"/>
    <path d="M9.5 0C4.5302 0 0.5 4.0302 0.5 9C0.5 13.9698 4.5302 18 9.5 18C14.4698 18 18.5 13.9698 18.5 9C18.5 4.0302 14.4698 0 9.5 0ZM14.6368 11.1307C14.6118 11.6808 14.5243 12.0564 14.3966 12.3852C14.1281 13.0794 13.5794 13.6281 12.8852 13.8966C12.5565 14.0243 12.1808 14.1117 11.6308 14.1368C11.0797 14.1619 10.9036 14.168 9.50014 14.168C8.0965 14.168 7.92058 14.1619 7.36934 14.1368C6.81934 14.1117 6.4436 14.0243 6.11497 13.8966C5.77 13.7668 5.45772 13.5634 5.19954 13.3005C4.93669 13.0424 4.73331 12.73 4.60353 12.3852C4.47581 12.0565 4.38834 11.6808 4.36334 11.1308C4.33794 10.5796 4.33203 10.4035 4.33203 9C4.33203 7.5965 4.33794 7.42044 4.3632 6.86934C4.3882 6.3192 4.47554 5.9436 4.60326 5.61484C4.73303 5.27 4.93655 4.95758 5.19954 4.69954C5.45758 4.43655 5.77 4.23317 6.11484 4.10339C6.4436 3.97568 6.8192 3.88834 7.36934 3.8632C7.92044 3.83807 8.0965 3.83203 9.5 3.83203C10.9035 3.83203 11.0796 3.83807 11.6307 3.86334C12.1808 3.88834 12.5564 3.97568 12.8852 4.10326C13.23 4.23303 13.5424 4.43655 13.8006 4.69954C14.0634 4.95772 14.267 5.27 14.3966 5.61484C14.5245 5.9436 14.6118 6.3192 14.6369 6.86934C14.6621 7.42044 14.668 7.5965 14.668 9C14.668 10.4035 14.6621 10.5796 14.6368 11.1307Z"/>
  </g>
  <defs>
    <clipPath id="clip0_835_46847">
      <rect width="18" height="18" fill="white" transform="translate(0.5)"/>
    </clipPath>
  </defs>
</svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
