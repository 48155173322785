import { doFetchList } from '@/services/agent/catalog.service'
import { getField, updateField } from 'vuex-map-fields'

export const catalogList = {
  namespaced: true,
  state: () => ({
    list: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setList(state, list) {
      state.list = list
    },
  },
  actions: {
    fetchList: async ({ commit }, { artistId }) => {
      try {
        const list = await doFetchList(artistId)
        commit('setList', list || [])
      } catch (err) {
        //
      }
    },
    setList: async ({ commit }, list) => {
      commit('setList', list)
    },
    resetList: async ({ commit }) => {
      commit('setList', [])
    },
  },
}
