<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 586.9 599.3"
    xml:space="preserve"
  >
    <path
      d="M515.2 57.1c-9.7 11.4-19.2 22.9-29 34.3-48.5 56.5-97.1 112.9-145.7 169.4-.4.5-.8 1-1.4 1.8 63.8 92.8 127.6 185.7 191.9 279.2-2.5.1-4.2.3-5.8.3-43.7 0-87.4 0-131.1.1-3.6 0-5.6-1.2-7.6-4.1-41.1-60-82.3-119.9-123.4-179.8-.9-1.3-1.8-2.5-3-4-13.1 15.3-26 30.2-38.9 45.1-39.7 46.2-79.5 92.3-119.1 138.6-2.6 3.1-5.3 4.2-9.3 4.2-11.8-.3-23.6-.1-36.9-.1 62.2-72.3 123.6-143.7 185.3-215.4-7.8-11.4-15.5-22.6-23.2-33.9-53-77.1-105.9-154.1-158.8-231.2-.9-1.4-1.6-3-2.3-4.5H198c40.6 59.1 81.2 118.1 122.2 177.7 1.8-2.1 3.3-3.7 4.7-5.3 40.5-47.1 81.1-94.2 121.6-141.4 8.8-10.3 17.4-20.7 26.2-31.1h42.5zm-42.5 454.5c-1.3-2.1-2.1-3.4-3-4.7-96.5-138-193-276-289.4-414.1-1.9-2.7-3.9-4-7.3-4-17.8.2-35.7.1-53.5.1-1.5 0-3 .2-5.2.4 1.6 2.3 2.6 4 3.8 5.6l185.4 265.2c34.4 49.2 68.8 98.5 103.4 147.7 1.1 1.6 3.2 3.5 4.8 3.6 20 .3 40.1.2 61 .2z"
    />
  </svg>
</template>
