<template>
  <svg class="custom-icon" viewBox="0 0 75 65" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m37.5 2.747 2.698 4.672 2.696 4.673h-2.473v11.966H34.58V12.092h-2.474l2.696-4.673L37.5 2.747M37.5 0c-.981 0-1.889.524-2.379 1.374l-2.697 4.674-2.696 4.671a2.74 2.74 0 0 0 0 2.747 2.743 2.743 0 0 0 2.104 1.359v9.232a2.747 2.747 0 0 0 2.748 2.747h5.842a2.747 2.747 0 0 0 2.747-2.747v-9.233a2.747 2.747 0 0 0 2.105-1.358 2.755 2.755 0 0 0 0-2.747l-2.698-4.671-2.697-4.674A2.75 2.75 0 0 0 37.5 0zM40.422 40.941v11.97h2.473l-2.696 4.673-2.699 4.673-2.697-4.673-2.696-4.673h2.474v-11.97h5.841m0-2.743H34.58a2.746 2.746 0 0 0-2.748 2.743v9.236c-.873.089-1.66.59-2.104 1.359a2.737 2.737 0 0 0 0 2.745l2.696 4.676 2.698 4.671A2.74 2.74 0 0 0 37.5 65a2.745 2.745 0 0 0 2.379-1.371l2.696-4.67 2.699-4.677a2.747 2.747 0 0 0-2.105-4.104v-9.236a2.746 2.746 0 0 0-2.747-2.744z"
    />
    <path
      d="m28.732 34.195 2.917 5.056-10.362 5.984 1.238 2.144H11.732l2.699-4.671 2.697-4.674 1.239 2.143 10.365-5.982m0-2.746c-.478 0-.952.124-1.374.366l-8 4.617a2.75 2.75 0 0 0-2.231-1.146h-.001c-.981 0-1.888.524-2.378 1.375l-2.697 4.671-2.698 4.673a2.747 2.747 0 0 0 2.378 4.12h10.793a2.745 2.745 0 0 0 2.502-3.879l7.996-4.617a2.746 2.746 0 0 0 1.005-3.75L31.11 32.82a2.74 2.74 0 0 0-2.378-1.371zM63.267 17.624l-2.696 4.673-2.7 4.672-1.234-2.145-10.369 5.984-2.912-5.056 10.361-5.986-1.238-2.143h10.788m0-2.746h-10.79a2.746 2.746 0 0 0-2.501 3.878l-7.998 4.619a2.748 2.748 0 0 0-1.006 3.75l2.913 5.057a2.75 2.75 0 0 0 3.753 1.007l8-4.618a2.75 2.75 0 0 0 2.23 1.146h.001c.983 0 1.889-.522 2.38-1.374l2.696-4.67 2.701-4.673a2.76 2.76 0 0 0 0-2.747 2.742 2.742 0 0 0-2.379-1.375z"
    />
    <path
      d="m46.267 34.195 10.369 5.981 1.234-2.143 2.7 4.674 2.696 4.671H52.477l1.236-2.144-10.359-5.984 2.913-5.055m0-2.746a2.747 2.747 0 0 0-2.381 1.374l-2.913 5.059a2.747 2.747 0 0 0 1.006 3.748l7.998 4.618c-.365.8-.321 1.732.124 2.504a2.745 2.745 0 0 0 2.377 1.374h10.789a2.747 2.747 0 0 0 2.38-4.12l-2.7-4.674-2.696-4.671a2.744 2.744 0 0 0-2.38-1.374h-.001a2.75 2.75 0 0 0-2.23 1.145l-8-4.616a2.75 2.75 0 0 0-1.373-.367zM11.733 17.623l5.395.001h5.397l-1.238 2.143 10.362 5.986-2.916 5.056-10.366-5.984-1.237 2.145-2.699-4.672-2.698-4.675m10.792.001h.003m-10.795-2.747a2.747 2.747 0 0 0-2.378 4.119l2.697 4.675 2.699 4.67a2.744 2.744 0 0 0 4.61.228l7.999 4.618a2.761 2.761 0 0 0 2.086.273 2.74 2.74 0 0 0 1.667-1.279l2.916-5.057a2.746 2.746 0 0 0-1.005-3.751l-7.994-4.617a2.745 2.745 0 0 0-2.501-3.88H11.733v.001z"
    />
  </svg>
</template>
