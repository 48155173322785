<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 20"
  >
    <g>
      <g>
        <path
          d="M10.428,10.947c-0.062,0-0.124-0.013-0.183-0.038c-1.707-0.711-2.81-2.364-2.81-4.213
					c0-2.516,2.047-4.562,4.563-4.562c2.517,0,4.564,2.047,4.564,4.562c0,1.849-1.103,3.502-2.81,4.213
					c-0.238,0.102-0.521-0.013-0.623-0.256c-0.101-0.243,0.014-0.521,0.257-0.623c1.351-0.562,2.223-1.871,2.223-3.334
					c0-1.991-1.62-3.61-3.611-3.61c-1.991,0-3.61,1.619-3.61,3.61c0,1.463,0.873,2.771,2.224,3.334
					c0.243,0.102,0.358,0.38,0.256,0.623C10.792,10.836,10.615,10.947,10.428,10.947z"
        />
        <path
          d="M17.277,20H6.72c-0.88,0-1.632-0.687-1.71-1.563l-0.353-3.979C4.654,12.025,6.6,10.079,8.99,10.079h1.439
					c0.263,0,0.476,0.214,0.476,0.477s-0.213,0.476-0.476,0.476H8.99c-1.866,0-3.384,1.52-3.384,3.385l0.352,3.936
					c0.034,0.378,0.383,0.696,0.762,0.696h10.558c0.38,0,0.729-0.318,0.762-0.697l0.354-3.977c-0.002-1.823-1.52-3.343-3.386-3.343
					H13.57c-0.264,0-0.477-0.213-0.477-0.476s0.213-0.477,0.477-0.477h1.438c2.392,0,4.336,1.946,4.336,4.337l-0.356,4.02
					C18.909,19.313,18.158,20,17.277,20z"
        />
      </g>
      <g>
        <path
          d="M19.004,7.478c-0.187,0-0.364-0.11-0.44-0.293c-0.101-0.242,0.014-0.521,0.257-0.623
					c1.091-0.454,1.796-1.512,1.796-2.693c0-1.607-1.309-2.915-2.917-2.915c-0.822,0-1.609,0.35-2.161,0.96
					c-0.176,0.195-0.478,0.211-0.673,0.034s-0.21-0.478-0.034-0.673C15.563,0.464,16.608,0,17.699,0c2.134,0,3.869,1.735,3.869,3.868
					c0,1.567-0.935,2.97-2.382,3.572C19.127,7.466,19.064,7.478,19.004,7.478z"
        />
        <path d="M16.396,7.072" />
        <path
          d="M22.082,14.993H20.75c-0.263,0-0.476-0.213-0.476-0.477c0-0.263,0.213-0.476,0.476-0.476h1.332
					c0.274,0,0.527-0.232,0.552-0.506l0.293-3.301c-0.002-1.463-1.225-2.687-2.729-2.687h-1.193c-0.264,0-0.477-0.213-0.477-0.476
					c0-0.264,0.213-0.477,0.477-0.477h1.193c2.029,0,3.68,1.651,3.68,3.68l-0.295,3.345C23.514,14.391,22.854,14.993,22.082,14.993z"
        />
      </g>
      <g>
        <path
          d="M4.995,7.478c-0.062,0-0.123-0.012-0.183-0.037C3.365,6.838,2.43,5.437,2.43,3.869
					C2.43,1.735,4.165,0,6.299,0c1.09,0,2.136,0.464,2.868,1.274C9.343,1.47,9.328,1.771,9.133,1.946
					C8.938,2.124,8.637,2.107,8.46,1.913c-0.552-0.61-1.34-0.96-2.162-0.96c-1.608,0-2.917,1.308-2.917,2.916
					c0,1.181,0.705,2.238,1.796,2.692C5.42,6.663,5.536,6.942,5.434,7.185C5.358,7.367,5.181,7.478,4.995,7.478z"
        />
        <path d="M7.602,7.072" />
        <path
          d="M3.248,14.993H1.916c-0.772,0-1.431-0.603-1.5-1.371l-0.293-3.304C0.121,8.247,1.771,6.597,3.8,6.597
					h1.194c0.263,0,0.476,0.213,0.476,0.476c0,0.264-0.213,0.477-0.476,0.477H3.8c-1.503,0-2.727,1.224-2.727,2.728l0.292,3.261
					c0.024,0.272,0.277,0.504,0.551,0.504h1.332c0.263,0,0.476,0.213,0.476,0.476C3.724,14.78,3.511,14.993,3.248,14.993z"
        />
      </g>
    </g>
  </svg>
</template>
