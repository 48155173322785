import { authHeader, openstageApiCrudUrl } from '../auth'
import { wrapRequest } from '../nav'

export const doFetchList = wrapRequest((artistId, fanId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  }
  return fetch(
    `${openstageApiCrudUrl}/fan_transaction?artist_id=eq.${artistId}&fan_id=eq.${fanId}`,
    requestOptions,
  )
})

export const doSummary = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(
    `${import.meta.env.VITE_OPENSTAGE_API2_URL}/fanTransactionSummaryType`,
    requestOptions,
  )
})

export const doSummaryDescription = wrapRequest((artistId, transactionType) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      transactionType,
    }),
  }
  return fetch(
    `${import.meta.env.VITE_OPENSTAGE_API2_URL}/fanTransactionSummaryDescription`,
    requestOptions,
  )
})
