import { getField, updateField } from 'vuex-map-fields'
import {
  // isEqual,
  cloneDeep,
} from 'lodash'
import { toSnakes } from '@/services/util.service'
import humps from 'lodash-humps'
import { doFetchOne, doPostOne, doDeleteOne, doPatchOne } from '@/services/agent/catalog.service'
import { toastification } from '@/plugins/toastification'

const emptyOne = {
  title: '',
  type: '',
  coverImage: null,
  releaseDate: null,
  isrc: null,
  upc: null,
  spotifyId: null,
  appleMusicId: null,
  deezerId: null,
}

export const catalogOne = {
  namespaced: true,
  state: () => ({
    error: { state: false },
    one: cloneDeep(emptyOne),
    oneUpdated: cloneDeep(emptyOne),
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    error(state, error) {
      state.error = error
    },
    one(state, one) {
      state.one = one
    },
    oneUpdated(state, one) {
      state.oneUpdated = one
    },
  },
  actions: {
    setOne({ commit }, one) {
      const humpedOne = humps(one)
      commit('one', humpedOne)
      commit('oneUpdated', cloneDeep(humpedOne))
    },
    newOne({ commit }) {
      const humpedOne = humps(emptyOne)
      commit('one', humpedOne)
      commit('oneUpdated', cloneDeep(humpedOne))
    },
    async fetchOne({ commit, dispatch }, catalogItemId) {
      try {
        dispatch('newOne')
        if (!catalogItemId) throw new Error('no catalog item id')
        const list = await doFetchOne(catalogItemId)
        if (!list || !list[0]) throw new Error('no such catalog item')
        const one = humps(list[0])
        dispatch('setOne', one)
        commit('error', { state: false }) // move this to a default
      } catch (err) {
        commit('error', {
          state: true,
          type: 'not found',
          message: 'Could not load this catalog item, please reload and retry.',
        })
      }
    },
    async deleteOne({ dispatch, state }) {
      // eslint-disable-next-line no-useless-catch
      try {
        await doDeleteOne(state.one.id)
        dispatch('newOne')
        return { removed: true }
      } catch (err) {
        throw err
      }
    },
    async saveOne({ state, dispatch }, artistId) {
      const one = toSnakes(JSON.parse(JSON.stringify(state.oneUpdated)))

      let responseOne = {}
      try {
        /* Prevent empty strings being saved. However, this wasn't needed on vue2. Suspect this is a v-input issue and requires fix there instead */
        if (!one.isrc) one.isrc = null
        if (!one.upc) one.upc = null
        one.artist_id = artistId
        if (state?.one?.id) {
          responseOne = await doPatchOne(one)
          toastification.success('Catalog item updated successfully')
        } else {
          responseOne = await doPostOne(one)
          toastification.success('Catalog item created successfully')
        }
        if (!responseOne.id) throw new Error('failed')
        dispatch('setOne', responseOne)
      } catch (error) {
        //
      }

      return responseOne.id
    },
  },
}
