<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 31">
    <path
      d="M15.5 6c-2.34 0-4.49.86-6.15 2.27C9.11 8.1 8.81 8 8.5 8 7.67 8 7 8.67 7 9.5c0 .4.16.76.42 1.03A9.48 9.48 0 0 0 6 15.5c0 4.75 3.5 8.68 8.05 9.38.17.64.75 1.12 1.45 1.12s1.28-.48 1.45-1.12c4.55-.7 8.05-4.63 8.05-9.38 0-5.24-4.26-9.5-9.5-9.5Zm1.36 17.88c-.24-.52-.76-.88-1.36-.88s-1.13.36-1.36.88C10.1 23.22 7 19.72 7 15.5c0-1.66.49-3.21 1.31-4.52.06 0 .12.02.19.02.83 0 1.5-.67 1.5-1.5 0-.14-.03-.28-.06-.41A8.443 8.443 0 0 1 15.5 7c4.69 0 8.5 3.81 8.5 8.5 0 4.22-3.1 7.73-7.14 8.38Z"
    />
    <circle cx="15" cy="15" r="3" />
    <path
      d="M29.4 22.31c1.01-2.06 1.6-4.36 1.6-6.81 0-5.41-2.79-10.18-7.01-12.95V2.5a1.498 1.498 0 0 0-2.36-1.23A15.33 15.33 0 0 0 15.49 0C6.95 0 0 6.95 0 15.5c0 2.97.85 5.74 2.31 8.1-.19.25-.31.56-.31.9a1.498 1.498 0 0 0 2.04 1.4C6.88 29.03 10.96 31 15.5 31c5.04 0 9.51-2.43 12.34-6.16.2.1.42.16.66.16.83 0 1.5-.67 1.5-1.5 0-.49-.24-.92-.6-1.19ZM15.5 30c-4.24 0-8.05-1.84-10.71-4.76.13-.22.21-.47.21-.74 0-.83-.67-1.5-1.5-1.5-.12 0-.24.02-.36.05C1.79 20.85 1 18.27 1 15.5 1 7.5 7.5 1 15.5 1c1.97 0 3.84.4 5.56 1.11-.03.12-.06.25-.06.39 0 .83.67 1.5 1.5 1.5.45 0 .84-.2 1.12-.51C27.47 6.1 30 10.51 30 15.5c0 2.34-.57 4.55-1.56 6.51-.8.03-1.44.69-1.44 1.49 0 .22.05.43.14.62C24.5 27.68 20.27 30 15.5 30Z"
    />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
