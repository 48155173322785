export const teamMembershipList = {
  namespaced: true,
  state: () => ({
    list: [],
    selected: [],
    inProgress: false,
    error: null,
  }),
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo
    },
    setList(state, list) {
      state.selected = []
      for (let i in state.list) {
        state.selected.push(false)
        state.selected[i] = false
      }
      state.list = list
    },
  },
  actions: {},
}
