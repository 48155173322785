const adminRoles = {
  organizationAdmin: 'organizationAdmin',
  superAdmin: 'superAdmin',
  artistAdmin: 'artistAdmin',
}

export const isAdmin = (text) => {
  return text && [adminRoles.organizationAdmin, adminRoles.superAdmin].includes(text)
}

export const isSuperAdminWithDemo = (text) => {
  return text && [adminRoles.superAdmin].includes(text) && !localStorage.getItem('mode')
}

export default adminRoles
