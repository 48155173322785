import { authHeader } from '../auth'
import { wrapRequest } from '../nav'

const openstageApi2Url = import.meta.env.VITE_OPENSTAGE_API2_URL

export const doFetchOne = (artistId, guildId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(
    `${openstageApi2Url}/discord?artistId=${artistId}&guildId=${guildId}`,
    requestOptions,
  )
    .then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json().then((x) => {
        if (x.length > 0) {
          return x[0]
        } else {
          return x
        }
      })
    })
    .catch((error) => handleError(error))
}

export const doPatchOne = wrapRequest((change) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(change),
  }
  return fetch(`${openstageApi2Url}/discord`, requestOptions)
})
