import { displayDatetime } from '@/_helpers/date'

// TODO - add subscriteria defaults
const criteriaTypes = {
  'Fan Has Upload': {
    defaults: {
      value: true,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-file-upload',
      searchTerms: 'upload image video text',
      title: 'Has uploaded media?',
    },
    validator: (c) => [true, false].includes(c.value),
  },
  'Fan Has Presave': {
    defaults: {
      condition: 'any',
      value: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-spotify',
      searchTerms: 'spotify apple deezer listen music',
      title: 'Has presaved?',
    },
    validator: (c) => ['yes', 'no'].includes(c.condition),
  },
  'Read Broadcast': {
    defaults: {
      condition: 'yes',
      value: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-broadcast',
      searchTerms: 'broadcast read unread sent bounce complain unsubscribe',
      title: 'Read Broadcast',
    },
    validator: (c) => c.value !== null,
    legacy: true,
  },
  'Not Read Broadcast': {
    defaults: {
      condition: 'no',
      value: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-broadcast',
      searchTerms: 'broadcast read unread sent bounce complain unsubscribe',
      title: 'Not Read Broadcast',
    },
    validator: (c) => c.value !== null,
    legacy: true,
  },
  'Fan Subscribed For Email': {
    defaults: {
      value: true,
    },
    meta: {
      group: 'connectivity',
      icon: 'mdi-email-open',
      searchTerms: 'e-mail inbox',
      title: 'Subscribed for emails?',
    },
    validator: (c) => [true, false, 'true', 'false'].includes(c.value),
  },
  'Fan Subscribed For SMS': {
    defaults: {
      value: true,
    },
    meta: {
      group: 'connectivity',
      icon: 'mdi-message-processing',
      searchTerms: 'text whatsapp inbox',
      title: 'Subscribed for SMS?',
    },
    validator: (c) => [true, false, 'true', 'false'].includes(c.value),
  },
  'Magnet Located In Country': {
    defaults: {
      condition: 'any of',
      value: [],
    },
    meta: {
      group: 'location',
      icon: 'mdi-earth',
      searchTerms: 'usa location place',
      title: 'Country',
    },
    validator: (c) =>
      ['any of', 'none of'].includes(c.condition) &&
      c.value?.length &&
      c.value.some((s) => criteriaTypes['Magnet Located In Country'].subItemValidator(s)),
    subItemValidator: (s) => !!s.countryCode,
  },
  location: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'location',
      icon: 'mdi-earth',
      searchTerms: 'usa location lives place home',
      title: 'City',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['location'].subItemValidator(s)),
    legacy: true,
    subItemValidator: (s) =>
      [true, false].includes(s.exists) && s.secondValue !== null && !!s.value?.id,
  },
  city: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'location',
      icon: 'mdi-earth',
      searchTerms: 'city location lives place home',
      title: 'City',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['location'].subItemValidator(s)),
    subItemValidator: (s) =>
      [true, false].includes(s.exists) && s.secondValue !== null && !!s.value?.id,
  },
  'Fan Has Location': {
    defaults: {
      condition: 'any',
      value: null,
    },
    meta: {
      group: 'location',
      icon: 'mdi-map-marker',
      searchTerms: 'location lives place',
      title: 'Has location?',
    },
    validator: (c) => ['yes', 'no'].includes(c.condition),
  },
  'Country Calling Codes': {
    defaults: {
      value: null,
    },
    meta: {
      group: 'location',
      icon: 'mdi-phone-plus',
      searchTerms: 'phone telephone',
      title: 'Country calling code',
    },
    validator: (c) => !!c.value?.length,
  },
  'Fan Played Presave': {
    defaults: {
      condition: 'yes',
      value: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-spotify',
      searchTerms: 'plays spotify apple deezer listen music track song album',
      title: 'Presave played',
    },
    validator: (c) => ['yes', 'no'].includes(c.condition),
  },
  'Fan Played Presave Count': {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-spotify',
      searchTerms: 'played spotify apple deezer listen music track song album',
      title: 'Presave plays',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['Fan Played Presave Count'].subItemValidator(s)),
    subItemValidator: (s) =>
      [true, false].includes(s.exists) &&
      s.fields?.length &&
      s.fields[0]?.field === 'track' &&
      s.fields[0]?.secondValue !== null &&
      s.fields[0]?.value !== null,
  },
  'Playlist Followers': {
    defaults: {
      condition: 'greater than',
      value: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-playlist-music-outline',
      searchTerms: 'follows',
      title: 'Playlist follows',
    },
    validator: (c) => c.condition === 'greater than' && c.value !== null,
  },
  'Includes Tags': {
    defaults: {
      condition: 'any of',
      value: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-tag',
      searchTerms: '',
      title: 'With tags',
    },
    validator: (c) => ['any of', 'all of'].includes(c.condition) && c.value?.length,
  },
  'Excludes Tags': {
    defaults: {
      condition: 'any of',
      value: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-tag-outline',
      searchTerms: '',
      title: 'Without tags',
    },
    validator: (c) => ['any of', 'all of'].includes(c.condition) && c.value?.length,
  },
  'Fan Has Number Of Tags': {
    defaults: {
      condition: 'yes',
      value: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-tag-multiple',
      searchTerms: 'count',
      title: 'Tag count',
    },
    validator: (c) =>
      ['greater than', 'less than', 'equal'].includes(c.condition) && c.value !== null,
  },
  'Fan Has Conversation': {
    defaults: {
      value: 'all',
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-message-processing',
      searchTerms: 'SMS unread',
      title: 'Conversation',
    },
    validator: (c) => ['yes', 'no', 'unread', 'all'].includes(c.value),
  },
  'Fan Has Been Created': {
    defaults: {
      condition: 'yes',
      value: null,
      secondValue: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-floppy',
      searchTerms: 'signed campaign',
      title: 'Signed up',
    },
    validator: (c) => c.condition === 'yes' && (c.value || c.secondValue),
  },
  'Fan Value': {
    defaults: {
      condition: 'yes',
      value: null,
      secondValue: null,
    },
    meta: {
      group: 'transactions',
      icon: 'mdi-cash',
      searchTerms: 'cost spend shopify paid spent ticket merch value money purchase bought year',
      title: 'One year fan value',
    },
    validator: (c) => c.condition === 'yes' && (c.value !== null || c.secondValue !== null),
  },
  'Fan Engagement': {
    defaults: {
      condition: 'yes',
      value: null,
      secondValue: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-counter',
      searchTerms: 'engagement score engage value activity active year',
      title: 'Engagement score',
    },
    validator: (c) => c.value !== null || c.secondValue !== null,
  },
  'Transaction Value': {
    defaults: {
      condition: 'yes',
      value: null,
      secondValue: null,
    },
    meta: {
      group: 'transactions',
      icon: 'mdi-cash',
      searchTerms: 'cost spend shopify paid spent ticket merch value money purchase bought',
      title: 'Transaction spend',
    },
    validator: (c) => c.condition === 'yes' && (c.value !== null || c.secondValue !== null),
  },
  'Fan Has Transaction Type': {
    defaults: {
      condition: 'like',
      value: null,
    },
    meta: {
      group: 'transactions',
      hidden: true,
      icon: 'mdi-cash',
      searchTerms: 'cost spend shopify paid spent ticket merch value money purchase bought',
      title: 'Transaction type',
    },
    validator: (c) => !!c.value, // legacy?
  },
  'Transaction Description': {
    defaults: {
      value: null,
      secondValue: [],
    },
    meta: {
      group: 'transactions',
      hidden: true,
      icon: 'mdi-cash',
      searchTerms: 'cost spend shopify paid spent ticket merch value money purchase bought',
      title: 'With transaction description',
    },
    validator: (c) => !!c.value, // legacy?
  },
  'No Transaction Description': {
    defaults: {
      value: null,
      secondValue: [],
    },
    meta: {
      group: 'transactions',
      hidden: true,
      icon: 'mdi-cash',
      searchTerms: 'cost spend shopify paid spent ticket merch value money purchase bought',
      title: 'Without transaction description',
    },
    validator: (c) => !!c.value, // legacy?
  },
  'Fan Has Permission': {
    defaults: {
      condition: 'like',
      value: null,
    },
    meta: {
      group: 'connectivity',
      icon: 'mdi-lock-open',
      searchTerms: 'type',
      title: 'Openstage permission',
    },
    validator: (c) =>
      ['like', 'unlike'].includes(c.condition) &&
      ['legitimate interest', 'marketing permission', 'openstage fan'].includes(c.value),
    legacy: true,
  },
  'Fan Age Like': {
    defaults: {
      condition: 'yes',
      value: null,
      secondValue: null,
    },
    meta: {
      group: 'personal',
      icon: 'mdi-human-female-boy',
      searchTerms: 'young old birthday date',
      title: 'Age',
    },
    validator: (c) => c.value !== null || c.secondValue !== null,
  },
  'Fan Name Like': {
    defaults: {
      condition: 'like',
      value: '',
    },
    meta: {
      group: 'personal',
      icon: 'mdi-format-text',
      searchTerms: 'surname forename',
      title: 'Name',
    },
    validator: (c) =>
      ['not exists', 'exists'].includes(c.condition) ||
      (['like', 'unlike'].includes(c.condition) && !!c.value),
  },
  'Fan Email Like': {
    defaults: {
      condition: 'like',
      value: '',
    },
    meta: {
      group: 'personal',
      icon: 'mdi-email',
      searchTerms: 'contact e-mail inbox',
      title: 'Email address',
    },
    validator: (c) =>
      ['not exists', 'exists'].includes(c.condition) ||
      (['like', 'unlike'].includes(c.condition) && !!c.value),
  },
  // legacy? not needed?
  // "Fan Company Like": {
  //   defaults: {
  //     condition: "like",
  //     value: "",
  //   },
  //   meta: {
  //     group: "personal",
  //     icon: "mdi-domain",
  //     searchTerms: "contact business company",
  //     title: "Company",
  //   },
  // },
  'Fan Phone Number Like': {
    defaults: {
      condition: 'like',
      value: '',
    },
    meta: {
      group: 'personal',
      icon: 'mdi-phone',
      searchTerms: 'telephone contact',
      title: 'Phone number',
    },
    validator: (c) =>
      ['not exists', 'exists'].includes(c.condition) ||
      (['like', 'unlike'].includes(c.condition) && !!c.value),
  },
  'National Phone Number': {
    defaults: {
      condition: 'exists',
      value: 'exists',
    },
    meta: {
      group: 'personal',
      icon: 'mdi-phone',
      searchTerms: 'telephone contact',
      title: 'National phone number',
    },
    validator: (c) =>
      ['not exists', 'exists'].includes(c.condition) &&
      ['not exists', 'exists'].includes(c.condition) &&
      !!c.value,
    legacy: true,
  },
  'Last Email Status Like': {
    defaults: {
      condition: 'like',
      value: null,
    },
    meta: {
      group: 'personal',
      icon: 'mdi-email-check',
      searchTerms: 'error',
      title: 'Email status',
    },
    validator: (c) => c.condition === 'like' && ['ok', 'error'].includes(c.value),
  },
  transaction: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'transactions',
      icon: 'mdi-cash',
      searchTerms: 'cost spend shopify paid spent ticket merch value money purchase date bought',
      title: 'Transaction',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['transaction'].subItemValidator(s)),
    subItemValidator: (s) => [true, false].includes(s.exists) && s.fields,
    // && (!s.fields?.length
    //   || (s.fields.some(f => f.value || f.from || f.to))) // TODO a bit vague, improve!
  },
  subscription: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-cash-sync',
      searchTerms: 'paid page fanpage subscription tier exclusive content',
      title: 'In subscription tier',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['subscription'].subItemValidator(s)),
    subItemValidator: (s) =>
      [true, false].includes(s.exists) &&
      s.fields?.length &&
      s.fields.every((e) => e.field === 'id' && !!e.value),
  },
  pageMetric: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-chart-bar',
      searchTerms: 'page metric',
      title: 'Page / Metric',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['pageMetric'].subItemValidator(s)),
    subItemValidator: (s) => [true, false].includes(s.exists), // TODO - improve?
  },
  'Fan Is Subscriber': {
    defaults: {
      value: true,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-cash-sync',
      searchTerms: 'paid page fanpage subscription tier exclusive content',
      title: 'Is subscriber?',
    },
    validator: (c) => [true, false].includes(c.value),
  },
  hasAddress: {
    defaults: {
      value: null,
      condition: 'any'
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-home',
      searchTerms: 'home postal address road street zip postcode',
      title: 'Has address?',
    },
    validator: (c) => ['yes', 'no'].includes(c.condition),
  },
  'Fan Has Discord': {
    defaults: {
      value: true,
    },
    meta: {
      group: 'engagement',
      icon: '$discord',
      searchTerms: 'fanpage subscription tier exclusive content discord',
      title: 'Has discord?',
    },
    validator: (c) => [true, false].includes(c.value),
  },
  broadcast: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-broadcast',
      searchTerms: 'broadcast read unread sent bounce complain unsubscribe',
      title: 'Broadcast',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['broadcast'].subItemValidator(s)),
    subItemValidator: (s) => [true, false].includes(s.exists),
  },
  activity: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-broadcast',
      searchTerms: 'engagement read listen play click link',
      title: 'Activity',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['activity'].subItemValidator(s)),
    subItemValidator: (s) => [true, false].includes(s.exists),
  },
  consent: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'connectivity',
      icon: 'mdi-lock-open-variant',
      searchTerms: 'sms email permission send',
      title: 'Consented?',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['consent'].subItemValidator(s)),
    subItemValidator: (s) =>
      [true, false].includes(s.exists) && ['', 'email', 'sms'].includes(s.field),
  },
  contactable: {
    defaults: {
      condition: '',
      value: 'yes', // yes/no
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-lock-open-variant',
      searchTerms: 'sms email permission send',
      title: 'Is Contactable',
    },
    validator: (c) => ['yes', 'no'].includes(c.value),
    legacy: true,
  },
  link: {
    defaults: {
      andor: 'and',
      criteria: [],
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-link',
      searchTerms: 'clicked broadcast read',
      title: 'Has clicked link?',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['link'].subItemValidator(s)),
    subItemValidator: (s) => [true, false].includes(s.exists),
  },
  'No Contact in Days': {
    defaults: {
      value: '',
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-tooltip-question-outline',
      searchTerms: 'uncontacted',
      title: 'Not contacted',
    },
    validator: (c) => !!c.value,
  },
  whereShallWePlay: {
    defaults: {
      andor: 'or',
      criteria: [],
    },
    meta: {
      group: 'location',
      icon: 'mdi-tooltip-question-outline',
      searchTerms: 'where shall we play should location wswp response',
      title: 'WSWP response',
    },
    validator: (c) =>
      ['and', 'or'].includes(c.andor) &&
      c.criteria?.length &&
      c.criteria.some((s) => criteriaTypes['whereShallWePlay'].subItemValidator(s)),
    subItemValidator: (s) => !!s.countryCode,
  },
  'Push Notifications Enabled': {
    defaults: {
      value: null,
    },
    meta: {
      group: 'engagement',
      icon: 'mdi-cellphone-sound',
      searchTerms: 'phone notify alert mobile content ',
      title: 'Has enabled push notifications?',
      titleShort: 'Push notifications?',
    },
    validator: (c) => ['yes', 'no'].includes(c.value),
  },
  'limit': {
    defaults: {
      value: null,
    },
    meta: {
      group: 'queue',
      icon: 'mdi-account-multiple-outline ',
      searchTerms: 'queue limit priority order',
      title: 'Limit number',
    },
    validator: (c) => !!c.value,
  },
  'order': {
    defaults: {
      value: 'random',
    },
    meta: {
      group: 'queue',
      icon: 'mdi-account-multiple-outline ',
      searchTerms: 'queue limit priority order',
      title: 'Order by',
    },
    validator: (c) => !!c.value,
  },
}

export const getDefaultCriteriaItem = (type) => {
  if (!type) return {}
  const structure = criteriaTypes[type]?.defaults
  if (!structure) return {}
  return {
    criteriaType: type,
    ...structure,
  }
}

const getCriteriaItemMeta = (type) => {
  if (!type) return {}
  const meta = criteriaTypes[type]?.meta
  if (!meta) return {}
  return {
    criteriaType: type,
    ...meta,
    searchTerms: `${meta.title} ${type} ${meta.searchTerms}`.toLowerCase(),
  }
}

export const getCriteriaItemTitle = (type) => {
  if (!type) return ''
  const meta = criteriaTypes[type]?.meta
  if (!meta) return ''
  return meta.title || ''
}

export const getListOfQueryOptions = () => {
  const reduced = []
  Object.keys(criteriaTypes).forEach((key) => {
    if (!criteriaTypes[key].legacy)
      reduced.push({
        criteriaType: key,
        ...getCriteriaItemMeta(key),
      })
  })
  return reduced
}

export const criteriaOptions = {
  activePreboarded: ['active', 'pre-boarded'],
  andOr: ['and', 'or'],
  anyOf: ['any of'],
  consentOptions: [
    {
      title: 'Any',
      value: '',
    },
    {
      title: 'Email',
      value: 'email',
    },
    {
      title: 'SMS',
      value: 'sms',
    },
  ],
  anyOfAllOf: [
    {
      title: 'is any of',
      value: 'any of',
    },
    {
      title: 'is all of',
      value: 'all of',
    },
  ],
  anyOfNoneOf: [
    {
      title: 'is any of',
      value: 'any of',
    },
    {
      title: 'is none of',
      value: 'none of',
    },
  ],
  conversationSelectList: ['yes', 'no', 'unread', 'all'],
  emailStatusSelectList: [
    { title: 'all', value: null },
    { title: 'ok', value: 'ok' },
    { title: 'error', value: 'error' },
  ],
  existsNotexist: [
    {
      title: 'exists',
      value: 'exists',
    },
    {
      title: "doesn't exist",
      value: 'not exists',
    },
  ],
  existsDoesntexist: [
    {
      title: 'exists',
      value: true,
    },
    {
      title: "doesn't exist",
      value: false,
    },
  ],
  flagSelectList: [
    { title: 'all', value: null },
    { title: 'no', value: false },
    { title: 'yes', value: true },
  ],
  flagSelectYesNo: [
    { title: 'no', value: false },
    { title: 'yes', value: true },
  ],
  isIsNot: [
    {
      title: 'is',
      value: 'like',
    },
    {
      title: 'is not',
      value: 'unlike',
    },
  ],
  like: ['like'], // exact match / something like
  likeUnlike: [
    {
      title: 'is like',
      value: 'like',
    },
    {
      title: 'is unlike',
      value: 'unlike',
    },
  ],
  moreLessEqual: ['greater than', 'less than', 'equal'],
  yes: ['yes'],
  yesNo: ['yes', 'no'],
  yesNoAny: ['any', 'yes', 'no'],
  yesNoAll: ['all', 'yes', 'no'],
}

/**
 * Returns a text representation of a criteriaList
 * takes additionalInfo as needed (eg. list of broadcast or subscription tier names, etc)
 */
export const doFormatCriteria = (criteria, additionalInfo) => {
  let text = ''
  const { currency, subscriptionTiers, broadcasts, pages, events, catalogListTracks } = additionalInfo
  switch (criteria.criteriaType) {
    case 'limit':
      return `limited to ${criteria.value} fans`
    case 'order':
      return `ordered ${criteria.value === 'random' ? 'randomly' : 'by engagement score'}`
    case 'Fan Name Like':
      return `${criteria.condition === 'not exists' ? 'do not' : ''} have a name${
        criteria.condition.includes('like') ? ` ${criteria.condition} ${criteria.value}` : ''
      }`

    case 'Fan Email Like':
      return `${criteria.condition === 'not exists' ? 'do not' : ''} have an email address${
        criteria.condition.includes('like') ? ` ${criteria.condition} ${criteria.value}` : ''
      }`

    case 'Fan Company Like':
      return 'work for a company ' + criteria.condition + ' ' + criteria.value

    case 'Fan Phone Number Like':
      return `${criteria.condition === 'not exists' ? 'do not' : ''} have a phone number${
        criteria.condition.includes('like') ? ` ${criteria.condition} ${criteria.value}` : ''
      }`

    case 'National Phone Number':
      return `${criteria.condition === 'not exists' ? 'do not' : ''} have a phone number`

    case 'Last Email Status Like':
      return 'have an ' + criteria.value + ' email status'

    case 'Fan Has Been Created':
      if (!criteria.secondValue) {
        return `have signed up after ${criteria.value}`
      } else if (!criteria.value) {
        return `have signed up before ${criteria.secondValue}`
      } else {
        return `have signed up between ${criteria.value} and ${criteria.secondValue}`
      }

    case 'Fan Subscribed For Email':
      return `have ${!criteria.value ? 'not' : ''} subscribed for emails`

    case 'Fan Subscribed For SMS':
      return `have ${!criteria.value ? 'not' : ''} subscribed for SMS`

    case 'Country Calling Codes':
      return `have registered the selected ${criteria.value.length} country calling codes`

    case 'Fan Age Like':
      if (!criteria.secondValue) {
        return `are older than ${criteria.value}`
      } else if (!criteria.value) {
        return `are younger than ${criteria.secondValue}`
      } else {
        return `are aged between ${criteria.value} and ${criteria.secondValue}`
      }

    case 'Fan Has Permission':
      return `are ${criteria.condition !== 'like' ? 'not' : ''} ${criteria.value} permission fans`

    case 'Fan Has Spotify':
      return `have ${!criteria.value ? 'not got' : ''} Spotify`

    case 'Fan Has Apple Music':
      return `have ${!criteria.value ? 'not got' : ''} Apple Music`

    case 'Fan Has Presave':
      text = 'have '
      if (criteria.condition === 'no') text += 'not '
      text += 'presaved'
      if (!criteria.value) return text
      return (
        text +
        ` ${criteria.value
          .map((data) => {
            return ' ' + data
          })
          .join(', ')}`
      )

    case 'Fan Played Presave':
      text = 'have '
      if (criteria.condition === 'no') text += 'not '
      text += 'played '
      if (!criteria.value?.length) text += 'any presave track'
      else if (criteria.value?.length === 1) text += 'the selected presave track'
      else text += `any of the selected ${criteria.value.length} presave tracks`
      return text

    case 'Fan Played Presave Count':
      if (criteria.criteria.length) {
        let returnText = []
        const presaves = criteria.criteria
        if (presaves.length && presaves.find((f) => f.fields.find((f2) => f2.value))) {
          presaves.forEach((fE) => {
            const track = fE.fields.find((f) => f.field === 'track')
            const catalogTitle =
              catalogListTracks.find(
                (catalogTrack) =>
                  catalogTrack.spotify_id === track.value ||
                  catalogTrack.deezer_id === track.value ||
                  catalogTrack.apple_music_id === track.value,
              )?.title || null
            returnText.push(
              `have${
                fE.exists ? '' : ' not'
              } played the selected track (${catalogTitle}) more than ${track.secondValue} times`,
            )
          })
          return returnText.join(`, ${criteria.andor} `)
        } else {
          return ''
        }
      }
      return ''

    case 'Fan Is Subscriber':
      return `${!criteria.value ? 'do not ' : ''}have a paid subscription`

    case 'Fan Has Conversation':
      return `${criteria.value === 'no' ? 'do not ' : ''}have ${
        criteria.value === 'unread'
          ? 'an unread'
          : criteria.value === 'all'
            ? 'or do not have a'
            : 'a'
      } conversation`

    case 'Fan Has Transaction Type':
      return !criteria.value || criteria.value.length < 1
        ? 'Fan has any transaction type'
        : 'Fan has ' +
            criteria.condition +
            ' the transactions: ' +
            (criteria.value ? criteria.value.join(', ') : '')

    case 'Transaction Value':
      if (!criteria.secondValue) {
        return `have spent more than ${currency}${criteria.value}`
      } else if (!criteria.value) {
        return `have spent less than ${currency}${criteria.secondValue}`
      } else {
        return `have spent between ${currency}${criteria.value} and ${currency}${criteria.secondValue}`
      }

    case 'Fan Value':
      if (!criteria.secondValue) {
        return `have a fan value of more than ${currency}${criteria.value}`
      } else if (!criteria.value) {
        return `have a fan value less than ${currency}${criteria.secondValue}`
      } else {
        return `have a fan value between ${currency}${criteria.value} and ${currency}${criteria.secondValue}`
      }

    case 'Fan Engagement':
      if (!criteria.secondValue) {
        return `who's engagement score is more than ${criteria.value}`
      } else if (!criteria.value) {
        return `who's engagement score is less than ${criteria.secondValue}`
      } else {
        return `who's engagement score is between ${criteria.value} and ${criteria.secondValue}`
      }

    case 'Transaction Description':
      return !(criteria.secondValue && criteria.secondValue.length)
        ? `have a transaction description`
        : `have transaction descriptions: ${criteria.secondValue}`

    case 'No Transaction Description':
      return !(criteria.secondValue && criteria.secondValue.length)
        ? `have not got a transaction description`
        : `have not got transaction descriptions: ${criteria.secondValue}`

    case 'Magnet Overlaps City':
      return `live within ${criteria.secondValue || 65}km of ${criteria.value
        .map((data) => {
          return data.city
        })
        .join(', ')}`

    case 'consent':
      if (criteria.criteria.length) {
        let returnText = []
        const consent = criteria.criteria
        if (consent.length) {
          consent.forEach((fE) => {
            returnText.push(
              `have ${fE.exists ? '' : 'not'} consented ${
                fE.field ? ` to receiving ${fE.field}` : 'to any broadcast type'
              }`,
            )
          })
          return returnText.join(`, ${criteria.andor} `)
        }
      }
      return 'have no consent status'

    case 'contactable':
      return `are${(criteria.value === 'no' && ' not') || ''} contactable`

    case 'location':
      if (criteria.criteria.length) {
        let returnText = []
        const locations = criteria.criteria
        if (locations.length && locations[0].value?.city && locations[0].value?.country) {
          locations.forEach((fE) => {
            if (fE.value?.city && fE.value?.country) {
              returnText.push(
                `${fE.exists ? '' : 'do not'} live within ${
                  fE.secondValue || 65
                }km of ${fE.value.city}, ${fE.value.country}`,
              )
            }
          })
          return returnText.join(`, ${criteria.andor} `)
        } else {
          return 'live nowhere'
        }
      }
      return 'live nowhere'

    case 'city':
      if (criteria.criteria.length) {
        let returnText = []
        const cities = criteria.criteria
        if (cities.length) {
          cities.forEach((fE) => {
            if (fE.value?.label || (fE.value?.city && fE.value?.country)) {
              let label = fE.value?.label || `${fE.value.city}, ${fE.value.country}`
              returnText.push(
                `${fE.exists ? '' : 'do not'} live in (or near) ${label}`,
              )
            }
          })
          return returnText.join(`, ${criteria.andor} `)
        }
        else {
          return 'live nowhere' // should not happen
        }
      }
      return 'live nowhere' // should not happen

    case 'Magnet Located In Country':
      return `have a country on record ${
        criteria.condition === 'any of' ? 'and it is' : "but it isn't"
      } ${joinOr(
        criteria.value.map((data) => {
          return data.country
        }),
      )}`

    case 'Fan Has Clicked Link':
      return `have ${criteria.condition === 'no' ? 'not' : ''} clicked the selected links`

    case 'Fan Has Location':
      if (criteria.condition === 'yes') {
        return 'have registered a location'
      } else {
        return 'have not registered a location'
      }

    case 'Push Notifications Enabled':
      if (criteria.value === 'yes') {
        return 'have enabled push notifications'
      } else {
        return 'have not enabled push notifications'
      }

    case 'Includes Tags':
      return (
        'have ' +
        criteria.condition +
        ' the tags: ' +
        (criteria.value ? criteria.value.join(', ') : '')
      )

    case 'Excludes Tags':
      return (
        'do not have ' +
        criteria.condition +
        ' the tags: ' +
        (criteria.value ? criteria.value.join(', ') : '')
      )

    case 'Fan Has Number Of Tags':
      return `have ${
        criteria.condition === 'greater than'
          ? 'more than '
          : criteria.condition === 'less than'
            ? 'fewer than '
            : ''
      }${criteria.value} tags`

    case 'Playlist Followers':
      return `have ${
        criteria.condition === 'greater than' ? 'more than' : 'fewer than'
      } ${criteria.value} playlist follows`

    case 'transaction':
      if (criteria.criteria.length) {
        let returnText = []
        const transactions = criteria.criteria
        if (transactions.length) {
          transactions.forEach((c) => {
            const date = c.fields.find((f) => f.field === 'date')
            const volume = c.fields.find((f) => f.field === 'volume')
            returnText.push(`have
							${c.exists ? '' : ' not'} purchased
									${
                    !volume
                      ? ''
                      : volume.from && volume.to
                        ? ` between ${volume.from} and ${volume.to}`
                        : volume.from
                          ? ` ${volume.from} or more`
                          : volume.to
                            ? ` ${volume.to} or fewer`
                            : ''
                  }
									${
                    c.fields.find((f) => f.field === 'description')?.value ||
                    (volume?.from || volume?.to
                      ? 'of something'
                      : c.exists
                        ? 'something'
                        : 'anything')
                  }
									${getDateRangeString(date)}
						`)
          })
          return returnText.join(`, ${criteria.andor} `)
        } else {
          return 'have or have not got a transaction'
        }
      }
      return 'have or have not got a transaction'

    case 'subscription':
      if (criteria?.criteria?.find((f) => f?.fields?.find((f2) => f2?.value))) {
        const items = criteria.criteria
        let returnTexts = []
        if (items?.length) {
          items.forEach((item) => {
            // WIP
            const tierNamesString = (
              item?.fields?.map((m) => subscriptionTiers.find((f) => m.value === f.id)?.name) || []
            )?.join(' or ')
            let returnText = ''
            if (tierNamesString) {
              returnText += 'have'
              if (!item.exists) returnText += ' not'
              returnText += ' subscribed to '
              returnText += tierNamesString
              returnText += ' tier(s)'
            }
            if (returnText) returnTexts.push(returnText)
          })
        }
        if (returnTexts.length) {
          return returnTexts.join(`, ${criteria.andor} `)
        }
      }
      return ''

    case 'pageMetric':
      if (criteria.criteria.length) {
        const items = criteria.criteria
        let returnTexts = []
        if (items.length) {
          items.forEach((item) => {
            let id = item.fields.find((f) => f.field === 'id')?.value
            if (id) {
              var returnText = 'have'
              if (!item.exists) returnText += ' not'
              returnText += ' visited '
              returnText += (id && pages.find((f) => id === f.id)?.title) || ' any page'
              returnText += ' / '
              let metric = item.fields.find((f) => f.field === 'metric')?.value
              if (metric) {
                returnText += metric
              } else {
                returnText += ' any metric'
              }
              returnText += `${getDateRangeString(item.fields.find((f) => f.field === 'date'))}`
              returnTexts.push(returnText)
            }
            
            let eventId = item.fields.find((f) => f.field === 'eventId')?.value
            let metric = item.fields.find((f) => f.field === 'metric')?.value
            if (eventId) {
              var returnText = 'have'
              if (!item.exists) returnText += ' not'
              if (metric === 'ticket-scan') {
                returnText += ' had a ticket scanned at '
              } else if (metric === 'ticket-purchase') {
                returnText += ' purchased a ticket for '
              }
              else {
                returnText += ' an action for '
              }
              returnText += (eventId && events.find((f) => eventId === f.id)?.title) || 'any event'
              returnText += `${getDateRangeString(item.fields.find((f) => f.field === 'date'))}`
              returnTexts.push(returnText)
            }
            
          })
        }
        if (returnTexts.length) {
          return returnTexts.join(`, ${criteria.andor} `)
        } else {
          return `have visited any page`
        }
      }
      return `have visited any page`

    case 'broadcast':
      if (criteria?.criteria?.length) {
        let returnTexts = []
        criteria.criteria.forEach((item) => {
          var returnText = 'have'
          if (!item.exists) returnText += ' not'
          if (item.fields.find((f) => f.field === 'sentAt')) {
            returnText += ` been sent`
          } else if (item.fields.find((f) => f.field === 'scheduledAt')) {
            returnText += ` been scheduled`
          } else if (item.fields.find((f) => f.field === 'bouncedAt')) {
            returnText += ` bounced back`
          } else if (item.fields.find((f) => f.field === 'readAt')) {
            returnText += ` read`
          } else if (item.fields.find((f) => f.field === 'complainedAt')) {
            returnText += ` complained after`
          } else if (item.fields.find((f) => f.field === 'unsubscribedAt')) {
            returnText += ` unsubscribed after`
          }

          let id = item.fields.find((f) => f.field === 'id')?.value
          const broadcastTitle = broadcasts.find((f) => f.id === id)?.description || '(unnamed)'

          returnText += id ? ` the broadcast with description: ${broadcastTitle}` : ' any broadcast'
          returnTexts.push(returnText)
        })

        if (returnTexts.length) {
          return returnTexts.join(`, ${criteria.andor} `)
        }
      }
      return ''

    case 'activity':
      if (criteria.criteria.length) {
        let returnText = []
        const activity = criteria.criteria
        if (activity.length) {
          activity.forEach((c) => {
            const date = c.fields.find((f) => f.field === 'date')
            returnText.push(`have 
								${c.exists ? '' : 'no '}
								${c.fields.find((f) => f.field === 'metric')?.value || (c.exists && 'some') || ''} activity
								${getDateRangeString(date)}`)
          })
          return returnText.join(`, ${criteria.andor} `)
        } else {
          return 'have or have not got activity'
        }
      }
      return 'have or have not got activity'

    case 'link':
      if (criteria.criteria.length) {
        let returnText = []
        const links = criteria.criteria
        if (links.length) {
          links.forEach((c) => {
            const date = c.fields.find((f) => f.field === 'date')
            returnText.push(`have 
								${c.exists ? 'clicked ' : 'not clicked '}
								${c.fields.find((f) => f.field === 'url')?.value || 'something'}
								${
                  c.fields.find((f) => f.field === 'broadcastId')?.value
                    ? `from the broadcast ${
                        broadcasts.find(
                          (f) =>
                            !!f.id &&
                            f.id === c.fields.find((f) => f.field === 'broadcastId')?.value,
                        )?.description
                      }`
                    : ''
                }
								${getDateRangeString(date)}`)
          })
          return returnText.join(`, ${criteria.andor} `)
        } else {
          return 'have or have not clicked links'
        }
      }
      return 'have or have not got clicked links'

    case 'Fan Has Upload':
      return `have ${!criteria.value ? 'not' : ''} uploaded media`

    case 'Fan Has Discord':
      return `have ${!criteria.value ? 'not' : ''} connected Discord`

    case 'hasAddress':
      return `have ${!criteria.condition ? 'not' : ''} listed their address`

    case 'No Contact in Days':
      return `have not been contacted in ${criteria.value} days`

    case 'whereShallWePlay':
      // TODO
      if (criteria.criteria.length) {
        return 'want you to play in the selected country (and city)'
      }
      return ''
    default:
      return 'match the criteria'
  }
}

// BE said to leave it, no conversion
const convertLegacyBroadcastCriteriaTypes = (criteria) => {
  if (!criteria) return []
  const broadcastSent = criteria.find((f) => f.criteriaType === 'Fan Has Been Sent Broadcast')
  const broadcastRead = criteria.find((f) => f.criteriaType === 'Read Broadcast')
  const broadcastNotRead = criteria.find((f) => f.criteriaType === 'Not Read Broadcast')
  const broadcastCriteria = []
  if (broadcastSent) {
    if (!broadcastSent.value === null) {
      broadcastCriteria.push({
        exists: true,
        fields: [{ field: 'sentAt' }],
      })
    } else if (broadcastSent.secondValue?.length) {
      broadcastCriteria.push({
        exists: !!broadcastSent.value,
        fields: [
          ...broadcastSent.secondValue.map((m) => ({ field: 'id', value: m })),
          { field: 'sentAt' },
        ],
      })
    }
  }
  if (broadcastRead && broadcastRead?.value?.length) {
    broadcastCriteria.push({
      exists: true,
      fields: [...broadcastRead.value.map((m) => ({ field: 'id', value: m })), { field: 'readAt' }],
    })
  }
  if (broadcastNotRead && broadcastNotRead?.value?.length) {
    broadcastCriteria.push({
      exists: false,
      fields: [
        ...broadcastNotRead.value.map((m) => ({ field: 'id', value: m })),
        { field: 'readAt' },
      ],
    })
  }
  const broadcastUnsubscribe = criteria.find((f) => f.criteriaType === 'broadcast')
  if (broadcastCriteria.length && broadcastUnsubscribe) {
    if (broadcastUnsubscribe.criteria) {
      broadcastUnsubscribe.criteria.push(...broadcastCriteria)
    } else {
      broadcastUnsubscribe.criteria = [...broadcastCriteria]
    }
  } else if (broadcastCriteria.length) {
    criteria.push({
      andor: 'and',
      criteriaType: 'broadcast',
      criteria: broadcastCriteria,
    })
  }
  return criteria.filter(
    (f) =>
      !['Fan Has Been Sent Broadcast', 'Read Broadcast', 'Not Read Broadcast'].includes(
        f.criteriaType,
      ),
  )
}

const convertLegacyFanHasTagsCriteriaType = (criteria) => {
  if (!criteria) return []
  const fanHasTags = criteria.find((f) => f.criteriaType === 'Fan Has Tags')
  if (!fanHasTags) return criteria

  fanHasTags.criteriaType = fanHasTags.condition === 'none of' ? 'Excludes Tags' : 'Includes Tags'
  return criteria
}

const convertLegacyTransactionCriteriaType = (criteria) => {
  if (!criteria) return []
  const transactionHasType = criteria.find((f) => f.criteriaType === 'Fan Has Transaction Type')
  const transactionDescription = criteria.find((f) => f.criteriaType === 'Transaction Description')
  const transactionDescriptionNot = criteria.find(
    (f) => f.criteriaType === 'No Transaction Description',
  )
  const transactionNewStructure = criteria.find((f) => f.criteriaType === 'transaction')
  const transactionCriteria = []
  if (transactionHasType && !!transactionHasType.value) {
    transactionCriteria.push({
      exists: true,
      fields: [{ field: 'type', value: transactionHasType.value }],
    })
  }
  if (
    transactionDescription &&
    transactionDescription.value &&
    transactionDescription?.secondValue?.length
  ) {
    transactionDescription.secondValue.forEach((f) => {
      transactionCriteria.push({
        exists: true,
        fields: [
          { field: 'type', value: transactionDescription.value },
          { field: 'description', value: f },
        ],
      })
    })
  }
  if (
    transactionDescriptionNot &&
    transactionDescriptionNot.value &&
    transactionDescription?.secondValue?.length
  ) {
    transactionDescriptionNot.secondValue.forEach((f) => {
      transactionCriteria.push({
        exists: true,
        fields: [
          { field: 'type', value: transactionDescriptionNot.value },
          { field: 'description', value: f },
        ],
      })
    })
  }

  if (transactionNewStructure && transactionCriteria.length) {
    if (transactionNewStructure.criteria) {
      transactionNewStructure.criteria.push(...transactionCriteria)
    } else {
      transactionNewStructure.criteria = [...transactionCriteria]
    }
  } else if (transactionCriteria.length) {
    criteria.push({
      andor: 'and',
      criteriaType: 'transaction',
      criteria: transactionCriteria,
    })
  }
  return criteria.filter(
    (f) =>
      ![
        'Fan Has Transaction Type',
        'Transaction Description',
        'No Transaction Description',
      ].includes(f.criteriaType),
  )
}

const convertLegacyHasClickedLinksCriteriaType = (criteria) => {
  if (!criteria) return []
  const fanHasClickedLink = criteria.find((f) => f.criteriaType === 'Fan Has Clicked Link')
  const newStrucutre = criteria.find((f) => f.criteriaType === 'link')
  const linksCriteria = []
  if (fanHasClickedLink) {
    if (fanHasClickedLink.value?.length) {
      fanHasClickedLink.value.forEach((f) => {
        linksCriteria.push({
          exists: ['yes', 'all'].includes(fanHasClickedLink.condition),
          fields: [{ field: 'url', value: f }],
        })
      })
    }
  }

  if (newStrucutre && linksCriteria.length) {
    if (newStrucutre.criteria) {
      newStrucutre.criteria.push(...linksCriteria)
    } else {
      newStrucutre.criteria = [...linksCriteria]
    }
  } else if (linksCriteria.length) {
    criteria.push({
      andor: 'and',
      criteriaType: 'transaction',
      criteria: linksCriteria,
    })
  }
  return criteria.filter((f) => !['Fan Has Clicked Link'].includes(f.criteriaType))
}
const convertLegacyHasSpotifyOrAppleMusicCriteriaType = (criteria) => {
  if (!criteria) return []
  // TODO - for now just filter out

  // const fanHasSpotify = criteria.find((f) => f.criteriaType === "Fan Has Spotify")
  // const fanHasAppleMusic = criteria.find((f) => f.criteriaType === "Fan Has Apple Music")
  // const fanHasPresave = criteria.find((f) => f.criteriaType === "Fan Has Presave")
  // if(!fanHasSpotify && !fanHasAppleMusic && !fanHasPresave) return criteria

  // const yes = []
  // const no = []

  // if(fanHasSpotify && fanHasSpotify.value) {
  //   yes.push('spotify')
  // }
  // else if(fanHasSpotify) {
  //   no.push('spotify')
  // }
  // if(fanHasAppleMusic && fanHasAppleMusic.value) {
  //   yes.push('apple')
  // }
  // else if(fanHasAppleMusic) {
  //   no.push('apple')
  // }
  // if(fanHasPresave && fanHasPresave.condition === 'yes') {
  //   (fanHasPresave.value || []).forEach(f => { if(!yes.includes(f)) yes.push(f) })
  //   if(yes.length) fanHasPresave.value = yes
  // }
  // else if(fanHasPresave && fanHasPresave?.condition === 'no') {
  //   (fanHasPresave.value || []).forEach(f => { if(!no.includes(f)) no.push(f) })
  //   if(no.length) fanHasPresave.value = no
  // }
  // else if(!fanHasPresave) {
  //   // hmm..
  // }

  // // push or amend here..
  // // ...

  return criteria.filter(
    (f) => !['Fan Has Spotify', 'Fan Has Apple Music'].includes(f.criteriaType),
  )
}

/**
 * Normalizes the given criteria by filtering out invalid values and converting legacy criteria types.
 * @param {Array} criteria - The criteria to be normalized.
 * @returns {Array} - The normalized criteria.
 */
export const normalizeCriteria = (criteria) => {
  if (!criteria?.length) return []
  let newCriteria = criteria
    .filter((f) => hasValueCriteria(f))
    .map((m) => {
      Object.keys(m).forEach((key) => {
        if (m[key] === null) delete m.key
      })
      if (m.criteria) {
        return {
          ...m,
          criteria: m.criteria.filter((f2) => hasValueCriteriaCriteria(m.criteriaType, f2)),
        }
      }
      return m
    })
  // BE said not to do this - investigate
  newCriteria = convertLegacyBroadcastCriteriaTypes(newCriteria)
  newCriteria = convertLegacyFanHasTagsCriteriaType(newCriteria)
  newCriteria = convertLegacyTransactionCriteriaType(newCriteria)
  newCriteria = convertLegacyHasClickedLinksCriteriaType(newCriteria)
  newCriteria = convertLegacyHasSpotifyOrAppleMusicCriteriaType(newCriteria)
  // other converisons/filters come here
  return newCriteria
}

/**
 * Checks if the given criteria has a criteria subvalue based on the criteria type.
 * @param {string} criteriaType - The type of criteria.
 * @param {any} criteriaCriteria - The subcriteria to be validated.
 * @returns {boolean} - Returns true if the criteria has a value, otherwise false.
 */
export const hasValueCriteriaCriteria = (criteriaType, criteriaCriteria) => {
  return criteriaTypes[criteriaType]?.subItemValidator(criteriaCriteria) || false
}

/**
 * Checks if the given criteria element has a value.
 * @param {Object} criteriaEl - The criteria element to check.
 * @returns {boolean} - Returns true if the criteria element has a value, otherwise false.
 */
export const hasValueCriteria = (criteriaEl) => {
  return criteriaTypes[criteriaEl.criteriaType]?.validator(criteriaEl) || false
}

/**
 * Checks if the given criteria element has the new criteria structure.
 * @param {Object} criteriaEl - The criteria element to check.
 * @returns {boolean} - True if the criteria element has the new structure, false otherwise.
 */
export const isNewCriteriaStructure = (criteriaEl) => {
  return !!criteriaEl.criteria
}

// text formatting
export const joinOr = (joinables) => {
  if (!joinables?.length) return ''
  return joinables.length > 1
    ? `${joinables.slice(0, -1).join(', ')} or ${joinables.slice(-1)}`
    : joinables[0] || ''
}

const getDateRangeString = (date = null) => {
  const dateFrom = date?.from ? displayDatetime(date.from) : null
  const dateTo = date?.to ? displayDatetime(date.to) : null
  return dateFrom && dateTo
    ? ` between ${dateFrom} and ${dateTo}`
    : dateFrom
      ? ` after ${dateFrom}`
      : dateTo
        ? ` before ${dateTo}`
        : ''
}
