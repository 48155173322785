import { Base64 } from 'js-base64'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

export var openstageApiCrudUrl = import.meta.env.VITE_OPENSTAGE_API_CRUD_URL
export var openstageApi2Url = import.meta.env.VITE_OPENSTAGE_API2_URL

const getAuthUser = () => {
  const userAuthEncoded = cookies?.get('uauth2') || null
  if (userAuthEncoded === null) return null
  return JSON.parse(Base64.decode(userAuthEncoded))
}

const getDomain = (domain) => {
  const s = domain.split('.')
  if (s.length < 3) {
    return domain
  }
  return s.slice(1).join('.')
}

const setAuthUser = (userAuth) => {
  const userAuthEncoded = Base64.encode(JSON.stringify(userAuth))
  cookies.set('uauth2', userAuthEncoded, '14d', null, getDomain(window.location.hostname))
}

const resetAuthUser = () => {
  cookies.remove('uauth2', null, getDomain(window.location.hostname))
}

const authHeader = () => {
  // return authorization header with jwt token
  const userAuth = getAuthUser()

  if (userAuth) {
    return { Authorization: 'Bearer ' + userAuth.auth }
  } else {
    return {}
  }
}

export { authHeader, getAuthUser, setAuthUser, resetAuthUser }
