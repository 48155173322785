import { createRouter, createWebHistory } from 'vue-router'
import store from '@/stores/agent'

const router = createRouter({
  // Set the router history mode to use browser history
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'Home',
      component: () => import('@/layouts/DefaultLayout.vue'),
      redirect: { name: 'Directory' },
      children: [
        {
          // Public routes accessible without authentication
          path: '/public',
          redirect: { name: 'TermsPage' },
          name: 'Terms',
          component: () => import('@/layouts/PublicLayout.vue'),
          // Children routes under the 'public' route
          children: [
            {
              path: 'terms',
              name: 'TermsPage',
              component: () => import('@/views/public/Terms.vue'),
              meta: {
                title: 'Terms',
              },
            },
            {
              path: 'sla',
              name: 'SLAPage',
              component: () => import('@/views/public/Sla.vue'),
              meta: {
                title: 'SLA',
              },
            },
          ],
        },
        {
          // Gate routes for authentication-related pages
          path: '/gate',
          name: 'Gate',
          redirect: { name: 'LoginPage' },
          component: () => import('@/layouts/GateLayout.vue'),
          // Children routes under the 'gate' route
          children: [
            {
              path: 'login',
              name: 'LoginPage',
              component: () => import('@/views/gate/Login.vue'),
              meta: {
                title: 'Login',
              },
            },
            {
              path: 'forgot-password',
              name: 'ForgotPassword',
              component: () => import('@/views/gate/ForgotPassword.vue'),
              meta: {
                title: 'Forgot Password',
              },
            },
            {
              path: 'reset-password',
              name: 'ResetPassword',
              component: () => import('@/views/gate/ResetPassword.vue'),
              meta: {
                title: 'Reset Password',
              },
            },
            {
              path: 'reset-password/openstage',
              name: 'ResetPasswordOpenstage',
              component: () => import('@/views/gate/ResetPassword.vue'),
              meta: {
                title: 'Reset Password Openstage',
              },
            },
            {
              path: 'new-account',
              name: 'NewAccountPage',
              redirect: { name: 'NewAccountStep1' },
              component: () => import('@/views/gate/NewAccount.vue'),
              children: [
                {
                  path: '',
                  name: 'NewAccountStep1',
                  component: () => import('@/components/modules/NewAccount/Step1.vue'),
                  meta: {
                    title: 'New Account Step 1',
                  },
                },
                {
                  path: 'details',
                  name: 'NewAccountStep2',
                  component: () => import('@/components/modules/NewAccount/Step2.vue'),
                  meta: {
                    title: 'New Account Step 2',
                  },
                },
                {
                  path: 'details-existing',
                  name: 'NewAccountStep2Existing',
                  component: () => import('@/components/modules/NewAccount/Step2.vue'),
                  meta: {
                    title: 'New Account Step 2 Existing',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'finish',
                  name: 'NewAccountStep3',
                  component: () => import('@/components/modules/NewAccount/Step3.vue'),
                  meta: {
                    title: 'New Account Step 3',
                    requiresAuth: true,
                  },
                },
              ],
            },
          ],
        },
        {
          // Authenticated routes for the artist directory
          path: '',
          name: 'Directory',
          redirect: { name: 'DirectoryPage' },
          component: () => import('@/layouts/DirectoryLayout.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'directory',
              name: 'DirectoryPage',
              component: () => import('@/views/manager/ArtistHome.vue'),
              meta: {
                title: 'Directory',
                requiresAuth: true,
              },
            },
            {
              path: 'profile',
              name: 'ProfilePage',
              component: () => import('@/views/manager/Profile.vue'),
              meta: {
                title: 'Profile',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          // Authenticated routes for manager artist pages
          path: ':artistId',
          name: 'Artist',
          component: () => import('@/layouts/AgentLayout.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: '',
              name: 'Dashboard',
              component: () => import('@/views/manager/Dashboard.vue'),
              meta: {
                title: 'Dashboard',
                requiresAuth: true,
                hideArtistAvatar: true,
              },
            },
            {
              path: 'dashboard-beta',
              name: 'DashboardBeta',
              component: () => import('@/components-atomic-design-system/views/Dashboard/DashboardBeta.vue'),
              meta: {
                title: 'Dashboard Beta',
                breadcrumb: 'Dashboard',
                // requiresAuth: true,
                requiresSuperAdmin: true,
              },
            },
            {
              path: 'fans',
              name: 'FansPage',
              //component: () => import('@/views/manager/fans/Fans.vue'), ADAM/MATE - Section template is potentially a better idea than global Empty, lets come back to this if needed
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'FansPageOverview' },
              meta: { 
                requiresAuth: true,
                breadcrumb: 'Fans',
             },
              children: [
                {
                  path: 'overview',
                  name: 'FansPageOverview',
                  component: () => import('@/views/manager/fans/Overview.vue'),
                  meta: {
                    title: 'Overview',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'list',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'FansPageList' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'FansPageList',
                      component: () => import('@/views/manager/fans/FanList.vue'),
                      meta: {
                        title: 'Fan List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':fanId',
                      name: 'FanEditPage',
                      component: () => import('@/views/manager/fans/Fan.vue'),
                      meta: {
                        title: 'Fan Edit',
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'audiences',
                  name: 'AudiencePage',
                  component: () => import('@/layouts/Empty.vue'),
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'FansPageAudiences',
                      component: () => import('@/views/manager/fans/AudienceList.vue'),
                      meta: {
                        title: 'Audience List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':audienceId',
                      name: 'AudienceEditPage',
                      component: () => import('@/views/manager/fans/AudienceEdit.vue'),
                      meta: {
                        title: 'Audience',
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'audiences-beta',
                  name: 'AudiencesBeta',
                  component: () => import('@/components-atomic-design-system/views/Audiences/AudiencesBeta.vue'),
                  meta: {
                    title: 'Audience Beta',
                    breadcrumb: 'Audiences',
                    // requiresAuth: true,
                    requiresSuperAdmin: true,
                  },
                  children: [
                    {
                      path: '',
                      name: 'AudienceList',
                      component: () => import('@/components-atomic-design-system/templates/Audiences/Audiences.vue'),
                      meta: {
                        title: 'Audience List',
                      },
                    },
                    {
                      path: 'new',
                      name: 'AudienceNew',
                      component: () => import('@/components-atomic-design-system/templates/Audiences/AudienceBuilder.vue'),
                      meta: {
                        title: 'Audience',
                      },
                    },
                    // {
                    //   path: ':audienceId',
                    //   name: 'AudienceEdit',
                    //   component: () => import('@/views/manager/fans/AudienceEdit.vue'),
                    //   meta: {
                    //     title: 'Audience',
                    //   },
                    // },
                  ],
                },
                {
                  path: 'import',
                  name: 'NewFanUploadPage',
                  component: () => import('@/views/manager/fans/Import.vue'),
                  meta: {
                    title: 'Fan Import',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'stories',
                  name: 'FansPageUploads',
                  component: () => import('@/views/manager/fans/Stories.vue'),
                  meta: {
                    title: 'Fan Uploads',
                    requiresAuth: true,
                  },
                },
              ],
            },

            // Engage
            {
              path: 'engage',
              name: 'EngagePage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'SMSDashboard' },
              meta: { requiresAuth: true },
              children: [
                {
                  path: 'fanpage',
                  name: 'FanPage',
                  component: () => import('@/views/manager/engage/Fanpage.vue'),
                  meta: {
                    title: 'Fanpage',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'pagelist',
                  name: 'Pages',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'PageList' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'PageList',
                      component: () => import('@/views/manager/engage/PageList.vue'),
                      meta: {
                        title: 'Page List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':pageId',
                      name: 'PageEditPage',
                      component: () => import('@/views/manager/engage/LegacyPageEdit.vue'),
                      meta: {
                        title: 'Landing Page Legacy',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'new-editor/:pageId',
                      name: 'LandingPageEditor',
                      component: () => import('@/views/manager/engage/LandingPageEdit.vue'),
                      meta: {
                        title: 'Landing Page',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'launch/:pageId',
                      name: 'LaunchPage',
                      component: () => import('@/views/manager/engage/LaunchPageEdit.vue'),
                      meta: {
                        title: 'Launch Page',
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'content',
                  name: 'Content',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'ContentList' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'ContentList',
                      component: () => import('@/views/manager/engage/ContentList.vue'),
                      meta: {
                        title: 'Content List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':contentId',
                      name: 'ContentEditPage',
                      component: () => import('@/views/manager/engage/ContentEdit.vue'),
                      meta: {
                        title: 'Content',
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'qrs',
                  name: 'QRCodeList',
                  component: () => import('@/views/manager/engage/QRCodes.vue'),
                  meta: {
                    title: 'QR List',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'broadcasts',
                  name: 'Broadcast',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'Broadcasts' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'Broadcasts',
                      component: () => import('@/views/manager/engage/broadcast/BroadcastList.vue'),
                      meta: {
                        title: 'Broadcast List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'new',
                      name: 'BroadcastNewView',
                      component: () =>
                        import('@/views/manager/engage/broadcast/new/BroadcastNew.vue'),
                      redirect: { name: 'BroadcastNewPage' },
                      meta: { requiresAuth: true },
                      children: [
                        {
                          path: '',
                          name: 'BroadcastNewPage',
                          component: () =>
                            import('@/views/manager/engage/broadcast/new/BroadcastNewSelector.vue'),
                          meta: {
                            title: 'Broadcast New',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: ':channel',
                          redirect: { name: 'BroadcastNewAudiencePage' },
                          children: [
                            {
                              path: 'details',
                              name: 'BroadcastNewEditPage',
                              component: () =>
                                import('@/views/manager/engage/broadcast/new/BroadcastNewEdit.vue'),
                              meta: {
                                title: 'Broadcast New Edit',
                                requiresAuth: true,
                              },
                            },
                            {
                              path: 'audience',
                              name: 'BroadcastNewAudiencePage',
                              component: () =>
                                import(
                                  '@/views/manager/engage/broadcast/new/BroadcastNewAudience.vue'
                                ),
                              meta: {
                                title: 'Broadcast New Audience',
                                requiresAuth: true,
                              },
                            },
                          ],
                          beforeEnter: (to, from, next) => {
                            const validChannels = ['email', 'sms', 'presave']
                            const { channel, ...params } = to.params
                            if (validChannels.includes(`${channel}`)) {
                              next()
                            } else {
                              next({ name: 'BroadcastNewPage', params, query: to.query })
                            }
                          },
                        },
                      ],
                    },
                    {
                      path: ':broadcastId',
                      name: 'BroadcastView',
                      component: () =>
                        import('@/views/manager/engage/broadcast/broadcastId/BroadcastId.vue'),
                      redirect: { name: 'BroadcastPage' },
                      meta: { requiresAuth: true },
                      children: [
                        {
                          path: '',
                          name: 'BroadcastPage',
                          component: () =>
                            import(
                              '@/views/manager/engage/broadcast/broadcastId/BroadcastView.vue'
                            ),
                          meta: {
                            title: 'Broadcast',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'edit',
                          name: 'BroadcastEditPage',
                          component: () =>
                            import(
                              '@/views/manager/engage/broadcast/broadcastId/BroadcastEdit.vue'
                            ),
                          meta: {
                            title: 'Broadcast Edit',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'audience',
                          name: 'BroadcastAudiencePageEdit',
                          component: () =>
                            import(
                              '@/views/manager/engage/broadcast/broadcastId/BroadcastAudience.vue'
                            ),
                          meta: {
                            title: 'Broadcast Audience',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'content',
                          name: 'BroadcastContentPage',
                          component: () =>
                            import(
                              '@/views/manager/engage/broadcast/broadcastId/BroadcastContent.vue'
                            ),
                          meta: {
                            title: 'Broadcast Content',
                            requiresAuth: true,
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'automation',
                  name: 'Automation',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'Automations' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'Automations',
                      component: () =>
                        import('@/views/manager/engage/automation/AutomationList.vue'),
                      meta: {
                        title: 'Automation List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'new',
                      name: 'AutomationNewPage',
                      component: () =>
                        import('@/views/manager/engage/automation/new/AutomationNew.vue'),
                      meta: {
                        title: 'Automation Create',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':broadcastId',
                      name: 'AutomationView',
                      component: () =>
                        import('@/views/manager/engage/automation/automationId/AutomationId.vue'),
                      redirect: { name: 'AutomationPage' },
                      meta: { requiresAuth: true },
                      children: [
                        {
                          path: '',
                          name: 'AutomationPage',
                          component: () =>
                            import(
                              '@/views/manager/engage/automation/automationId/AutomationView.vue'
                            ),
                          meta: {
                            title: 'Automation',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'edit',
                          name: 'AutomationEditPage',
                          component: () =>
                            import(
                              '@/views/manager/engage/automation/automationId/AutomationEdit.vue'
                            ),
                          meta: {
                            title: 'Automation Edit',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'setup',
                          name: 'AutomationSetupPage',
                          component: () =>
                            import('@/views/manager/engage/automation/new/AutomationNew.vue'), // using same component as /new
                          meta: {
                            title: 'Automation Setup',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'audience',
                          name: 'AutomationAudiencePage',
                          component: () =>
                            import(
                              '@/views/manager/engage/automation/automationId/AutomationAudience.vue'
                            ),
                          meta: {
                            title: 'Automation Audience',
                            requiresAuth: true,
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'templates',
                  name: 'EmailTemplates',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'Templates' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'Templates',
                      component: () => import('@/views/manager/engage/EmailTemplateList.vue'),
                      meta: {
                        title: 'Email Master Template List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':emailMasterTemplateId',
                      name: 'EmailMasterTemplateEditPage',
                      component: () => import('@/views/manager/engage/EmailTemplateEdit.vue'),
                      meta: {
                        title: 'Email Master Template',
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'tags',
                  name: 'TagManager',
                  component: () => import('@/views/manager/engage/TagManager.vue'),
                  meta: {
                    title: 'Tag Manager',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'sms',
                  name: 'SMSDashboard',
                  component: () => import('@/views/manager/engage/SMSDashboard.vue'),
                  meta: {
                    title: 'SMS Dashboard',
                    requiresAuth: true,
                  },
                },
              ],
            },

            // Analytics
            {
              path: 'analytics',
              name: 'AnalyticsPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'EngagementMetricsPage' },
              meta: { requiresAuth: true },
              children: [
                {
                  path: 'enagement',
                  name: 'EngagementMetricsPage',
                  component: () => import('@/views/manager/analytics/EngagementMetrics.vue'),
                  meta: {
                    title: 'Engagement Metrics',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'transactions',
                  name: 'TransactionsMetricsPage',
                  component: () => import('@/views/manager/analytics/TransactionsMetrics.vue'),
                  meta: {
                    title: 'Transactions Metrics',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'metrics',
                  name: 'ArtistMetricsPage',
                  component: () => import('@/views/manager/analytics/ArtistMetrics.vue'),
                  meta: {
                    title: 'Artist Metrics',
                    requiresAuth: true,
                  },
                },
              ],
            },

            // Artist Settings
            {
              path: 'settings',
              name: 'SettingsPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'ArtistEditPage' },
              meta: { requiresAuth: true },
              children: [
                {
                  path: 'artist',
                  name: 'ArtistEditPage',
                  component: () => import('@/views/manager/settings/Artist.vue'),
                  meta: {
                    title: 'Artist Settings',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'organization',
                  name: 'Organization',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'OrganizationOverviewPage' },
                  meta: { requiresAdmin: true },
                  children: [
                    {
                      path: '',
                      name: 'OrganizationOverviewPage',
                      component: () =>
                        import('@/views/manager/settings/Organisation/Organisation.vue'),
                      meta: {
                        title: 'Account Overview',
                        requiresAdmin: true,
                      },
                    },
                    {
                      path: 'team/:teamId',
                      name: 'OrganizationTeam',
                      component: () =>
                        import('@/views/manager/settings/Organisation/OrganisationTeam.vue'),
                      meta: {
                        title: 'Account Team',
                        requiresAdmin: true,
                      },
                    },
                    {
                      path: 'team/:teamMembershipId/edit',
                      name: 'OrganizationTeamMembershipEditPage',
                      component: () =>
                        import(
                          '@/views/manager/settings/Organisation/OrganisationTeamMembershipEdit.vue'
                        ),
                      meta: {
                        title: 'Account Team Membership',
                        requiresAdmin: true,
                      },
                    },
                  ],
                },
                {
                  path: 'catalog',
                  name: 'Catalog',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'CatalogList' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'CatalogList',
                      component: () => import('@/views/manager/settings/Catalog/CatalogList.vue'),
                      meta: {
                        title: 'Catalog List',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'new',
                      name: 'CatalogItemNew',
                      component: () => import('@/views/manager/settings/Catalog/CatalogNew.vue'),
                      meta: {
                        title: 'Catalog Create',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':id',
                      name: 'CatalogId',
                      component: () => import('@/layouts/Empty.vue'),
                      redirect: { name: 'CatalogItem' },
                      meta: { requiresAuth: true },
                      children: [
                        {
                          path: '',
                          name: 'CatalogItem',
                          component: () =>
                            import('@/views/manager/settings/Catalog/CatalogView.vue'),
                          meta: {
                            title: 'Catalog',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'edit',
                          name: 'CatalogItemEdit',
                          component: () =>
                            import('@/views/manager/settings/Catalog/CatalogEdit.vue'),
                          meta: {
                            title: 'Catalog Edit',
                            requiresAuth: true,
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'ecommerce',
                  name: 'Ecommerce',
                  component: () => import('@/views/manager/settings/Ecommerce.vue'),
                  meta: {
                    title: 'Ecommerce Setup',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'discord',
                  name: 'Discord',
                  component: () => import('@/views/manager/settings/Discord.vue'),
                  meta: {
                    title: 'Discord Setup',
                    requiresAuth: true,
                  },
                },
              ],
            },

            // Admin Settings
            {
              path: 'admin',
              name: 'AdminPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'Activity' },
              meta: { requiresSuperAdmin: true },
              children: [
                {
                  path: 'artist-activity',
                  name: 'Activity',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'ArtistActivityPage' },
                  meta: { requiresSuperAdmin: true },
                  children: [
                    {
                      path: '',
                      name: 'ArtistActivityPage',
                      component: () => import('@/views/manager/admin/ArtistActivityList.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                    {
                      path: ':artist',
                      name: 'ArtistActivityOrganizationPage',
                      component: () => import('@/views/manager/admin/ArtistActivityView.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                  ],
                },
                {
                  path: 'artist-financials',
                  name: 'ArtistFinancialsPage',
                  component: () => import('@/views/manager/admin/ArtistFinancialsPage.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                {
                  path: 'fan-activity',
                  name: 'FanActivityPage',
                  component: () => import('@/views/manager/admin/FanActivity.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                {
                  path: 'fan-engagement',
                  name: 'FanEngagementPage',
                  component: () => import('@/views/manager/admin/FanEngagement.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                {
                  path: 'organization',
                  name: 'OrganizationManagement',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'OrganizationManagementPage' },
                  meta: { requiresSuperAdmin: true },
                  children: [
                    {
                      path: '',
                      name: 'OrganizationManagementPage',
                      component: () => import('@/views/manager/admin/OrganizationManagement.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                    {
                      path: ':orgId',
                      name: 'OrganizationEditPage',
                      component: () => import('@/views/manager/admin/OrganizationEdit.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                  ],
                },
                {
                  path: 'fan',
                  name: 'FanManagementPage',
                  component: () => import('@/views/manager/admin/FanManagement.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                // {
                //   path: 'query',
                //   name: 'AIQueryPage',
                //   component: () => import('@/views/manager/admin/Cities.vue'),
                //   meta: { requiresSuperAdmin: true },
                // },
              ],
            },

            // {
            //   path: 'twilio',
            //   name: 'Twilio',
            //   beforeEnter(to, from, next) {
            //     next({
            //       name: 'ArtistEditPage',
            //       params: to.params,
            //       query: {
            //         ...(to.query || {}),
            //         tab: 'phone',
            //       },
            //       replace: true,
            //     })
            //   },
            // },
          ],
        },

        // Root third party path/redirect pages
        {
          path: '/composer',
          name: 'EmailComposerPage',
          component: () => import('@/views/manager/EmailComposer.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/stripeConnect',
          name: 'StripeConnect',
          component: () => import('@/views/manager/StripeConnected.vue'),
          meta: { requiresAuth: true },
        },
      ],
    },

    // Other
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/layouts/NotFoundLayout.vue'),
    },
  ],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('error loading dynamically imported module') ||
    error.message.includes('Unable to preload CSS') ||
    error.message.includes("'text/html' is not a valid JavaScript MIME type")
  ) {
    window.location = to.fullPath as any
  }
})

router.beforeEach((to, from, next) => {
  const isLoggedinUser = store?.getters['auth/isUser']
  const isAdminUser = store?.getters['auth/isAdmin']
  const isSuperAdminUser = store?.getters['auth/isAdmin']

  if (
    !isLoggedinUser &&
    to.matched.some(
      (record) =>
        record.meta.requiresAuth || record.meta.requiresAdmin || record.meta.requiresSuperAdmin,
    )
  ) {
    if (sessionStorage) sessionStorage.interrupted_page = to.fullPath
    next({ path: '/gate', query: to.query })
  } else if (
    !isAdminUser &&
    to.matched.some((record) => record.meta.requiresAdmin || record.meta.requiresSuperAdmin)
  ) {
    next('')
  } else if (!isSuperAdminUser && to.matched.some((record) => record.meta.requiresSuperAdmin)) {
    next('')
  } else {
    document.title = `${to.meta?.title ? `${to.meta?.title} - ` : ''}Openstage Manager`
    next()
  }
})

export default router
