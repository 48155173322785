<template>
  <svg class="custom-icon" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <path d="m21 12.93-7-7v4H3.8c-.28 0-.42 0-.527.054a.5.5 0 0 0-.219.219C3 10.31 3 10.45 3 10.73v4.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.219c.107.054.247.054.527.054H14v4l7-7Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<style scoped>
.svg-icon {
  stroke: currentColor;
}
</style>
