import moment from 'moment'

export const formats = {
  guiDatetimeFormat: "MMM Do 'YY, h:mma", // TODO - read from user preferences store (gets from api)
  guiDatetimeShortFormat: "MM-DD-YYYY, h:mm a",
  guiDateOnlyFormat: "MMM Do 'YY",
  guiDateOnlyNoYearFormat: "MMM Do",
  guiTimeOnlyFormat: "h:mma",
  apiDateOnlyFormat: 'YYYY-MM-DD',
  apiTimeOnlyFormat: 'HH:mm:ss',
  apiDatetimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
}

export const defaultMinDatetime = '1950-01-01T00:00:00.000Z'

let timezone = null
export const timezoneSingleton = () => {
  return timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const displayDatetime = (utcIso = null) => {
  if(!utcIso) return null
  // convert from prop (utc ISO 8601 string)
  const momObj = moment.utc(utcIso)
  if(!momObj?.isValid()) return null
  return momObj.local().format(formats.guiDatetimeFormat)
}

export const displayDatetimeShort = (utcIso = null) => {
  if(!utcIso) return null
  // convert from prop (utc ISO 8601 string)
  const momObj = moment.utc(utcIso)
  if(!momObj?.isValid()) return null
  return momObj.local().format(formats.guiDatetimeShortFormat)
}

export const displayDateOnly = (dateString = null) => {
  if(!dateString) return null
  // convert from prop (YYYY-MM-DD string)
  const momObj = moment(dateString)
  if(!momObj?.isValid()) return null
  return momObj.format(formats.guiDateOnlyFormat)
}

export const displayTimeOnly = (timeString = null) => {
  if(!timeString) return null
  // convert from prop (YYYY-MM-DD string)
  const momObj = moment(`2024-01-01T${timeString}`)
  if(!momObj?.isValid()) return null
  return momObj.format(formats.guiTimeOnlyFormat)
}

export const getNowUtc = () => {
  return moment.utc().format(formats.apiDatetimeFormat)
}
