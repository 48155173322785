import { authHeader, openstageApiCrudUrl } from '../auth'
const openstageApi2Url = import.meta.env.VITE_OPENSTAGE_API2_URL
import { wrapRequest, wrapRequestWithRange, wrapRequestNoError } from '../nav'

export const doPatchOne = wrapRequest((organization) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(organization),
  }
  return fetch(`${openstageApiCrudUrl}/organization?id=eq.${organization.id}`, requestOptions)
})

export const redirectToBillingPortal = wrapRequest((organizationId, returnUrl) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ organizationId, returnUrl }),
  }
  return fetch(`${openstageApi2Url}/stripePortalRedirect`, requestOptions)
})

export const redirectToSmsBillingPortal = wrapRequest((organizationId, returnUrl) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ organizationId, returnUrl }),
  }
  return fetch(`${openstageApi2Url}/stripeSmsPortalRedirect`, requestOptions)
})

export const doConnectStripe = wrapRequest((organizationId, code) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ organizationId, code }),
  }
  return fetch(`${openstageApi2Url}/connectStripe`, requestOptions)
})

export const disconnectStripe = wrapRequest((organizationId, clientId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ organizationId, clientId }),
  }
  return fetch(`${openstageApi2Url}/disconnectStripe`, requestOptions)
})

export const doConnectTicketStripe = wrapRequest((artistId, code) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, code }),
  }
  return fetch(`${openstageApi2Url}/ticketStripeConnect`, requestOptions)
})
export const doDisconnectTicketStripe = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/ticketStripeDisconnect`, requestOptions)
})

export const doConnectSubscriptionStripe = wrapRequest((artistId, code) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, code }),
  }
  return fetch(`${openstageApi2Url}/subscriptionStripeConnect`, requestOptions)
})
export const doDisconnectSubscriptionStripe = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/subscriptionStripeDisconnect`, requestOptions)
})

export const doPostOne = wrapRequest((organization) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(organization),
  }
  return fetch(`${openstageApiCrudUrl}/organization`, requestOptions)
})

export const doFetchOrganizationMetrics = wrapRequest((organizationId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ organizationId }),
  }
  return fetch(`${openstageApi2Url}/organizationMetrics`, requestOptions)
})

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/organization?id=eq.${id}`, requestOptions)
})

export const subscribeArtist = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/subscribeArtist`, requestOptions)
})

export const invoiceNewAccount = wrapRequest((newAccountId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ newAccountId }),
  }
  return fetch(`${openstageApi2Url}/subscribeNewAccount`, requestOptions)
})

export const doDeleteSelected = wrapRequest((organizationIds) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/organization?id=in.(${organizationIds})`, requestOptions)
})

export const doFetchList = wrapRequestWithRange((appId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  }
  return fetch(`${openstageApiCrudUrl}/organization?app_id=eq.${appId}`, requestOptions)
})

export const doLookup = wrapRequestWithRange((search, appId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      Prefer: 'count=exact',
    },
  }
  return fetch(
    `${openstageApiCrudUrl}/organization?name=ilike.*${search.name}*&app_id=eq.${appId}`,
    requestOptions,
  )
})

export const doFetchSmsCredit = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  }
  return fetch(`${openstageApi2Url}/smsCredit`, requestOptions)
})

export const doSmsTopUp = wrapRequestNoError((artistId, amount) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, amount }),
  }
  return fetch(`${openstageApi2Url}/smsTopup`, requestOptions)
})

export const doOrganizationNextInvoices = wrapRequest((organizationId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  }
  return fetch(
    `${openstageApi2Url}/organizationNextInvoices?organizationId=${organizationId}`,
    requestOptions,
  )
})
